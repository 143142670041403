@import "scss/base.scss";


.methodOfContactContainer {

  &.displayNone {
    display: none;
  }

  > div {
    margin-top: $margin-tiny;
  }

  .greyOpacity {
    opacity: 0.4;
  }

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
  .methodOfContactContainer {

    &.displayNone {
      display: block;
      opacity: 0;
    }
  
    > div {
      margin-top: $margin-tiny;
    }
  
    .greyOpacity {
      opacity: 0.4;
    }
  
  }
}
