@import "scss/base.scss";
@import "scss/forms.scss";
@import "scss/icons.scss";

.singleInputCard {
    @include cardStyle;

    display: flex;
    width: $full;

    p { 
        color: $white;
        font-size: $font-size-medium;
        margin-left: $margin-tinier;
        margin-right: $margin-tiny;
    }

    .arrowIcon {
        width: $icon-size-small;
        height: $icon-size-small;
    }

    &.thick {
        padding: $padding-tiny;
        padding-right: $padding-medium;

        flex-direction: column;
        p {
            margin-bottom: $margin-tiny;
        }
    }

    &.thin {
        padding: $padding-tiny;
        flex-direction: row;
        .inputContainer {
            height: $input-min-height-small;
        }
        p {
            display: flex;
            align-items: center;
        }
    }
}

.inputContainer {
    @include input-container;
    input {
        font-family: Futura;
        padding: 0;
    }
    border: $border-size-medium solid $border-color;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .singleInputCard {
        width: $two-third;

        p {
            font-size: $font-size;
        }
    }

    .inputContainer {
        input {
            font-size: $font-size;
        }
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .singleInputCard {
        p {
            font-size: $font-size;
        }

        &.thick {
            width: 3 * $one-fourth;
        }
        &.thin {
            width: $full;
        }
    }

    .inputContainer {
        input {
            font-size: $font-size;
        }
    }
}
