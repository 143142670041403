@import "scss/base.scss";

.updateSubmitted {
    @include fadeInFromBottom;

    opacity: 0;

    > * {
        margin-bottom: 15px;
    }

    .acknowledgementOfConsent {
        display: flex;
        align-items: center;
    }

}

.label {
    font-size: $font-size-small;
    margin-bottom: $margin-tiniest;
} 

.basicInfo {
    display: flex;
    flex-wrap: wrap;

    .basicInfoField {
        width: 50%;
    }
}

.section {
    padding: 20px 0; //placeholder to help visually distinguish sections for now
}

div.iconSize {
    width: $icon-size;
    height: $icon-size;
}

div.reportInformationImage {
    height: $illustration-size-small;
    margin: $margin-tiny 0;
}

.updateSubmittedStepHeader {
    display: flex;
    p:first-child {
        flex: 1;
    }
}

.pageFooter {
    float: right;
}

.reportInfoHeader {
    padding: $padding-tiny 0;
    margin-left: 0;
    border-bottom: solid 1px $border-color;
    margin-bottom: $margin-tiny;
    display: flex;
    align-items: center;

    h1 {
        flex: 1;
    }
}

.editIcon {
    width: $icon-size;
    height: $icon-size;
    display: inline-block;
    vertical-align: sub;
    margin-right: $margin-tiny;
}

.infoExplainer {
    display: flex;
    align-items: center;
    margin: $margin-tiny 0 $margin-largest;

    .explanation {
        flex: 1;
        margin-left: $margin-tiny;
        font-size: $font-size-small;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .reportInfoHeader {
        padding: $padding-medium $padding-largest;
        margin-left: -$margin-largest;
        padding-right: 0;
    }

    div.reportInformationImage {
        height: $illustration-size-medium;
        margin: $margin-tiny 0;
    }
}