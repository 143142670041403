@import "scss/base.scss";

.illustration {
    height: $illustration-size-small;
}

.subheader {
    margin-top: $margin-small;
}

.savedReportModalContent {
    text-align: center;
    max-width: $modal-max-width;
    margin: auto;

    .reportKey {
        font-size: $font-size-largest;
        margin: $margin-large 0;
        color: $vesta-pink;
    }
}

.saveAndExitLink {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}



/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .illustration {
        display: none;
    }
}
