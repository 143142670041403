@import "scss/base.scss";

.contentContainer {
    padding-top: $padding-tiny;
    position: relative; 
    display: block;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .contentContainer { 
        padding-top: $padding-medium;
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .contentContainer {
        padding-top: $padding-large;
        display: flex;
    }
}
