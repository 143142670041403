@import "scss/base.scss";

.personalInfoContainer {
  max-width: $general-page-max-width;
  padding: 0px $padding-small;
  margin: auto;
}

div.otherReportKeys {
  display: flex;
}

.acknowledgementOfConsent {
  display: flex;
  align-items: center;
  margin: 0;
  text-align: center;
  font-family: FuturaDemi;
  color: $vesta-dark-pink;
  line-height: 1rem;
}

div.iconSize {
  width: $icon-size;
  height: $icon-size;
  margin-left: $margin-tiniest;
  display: inline-block;
  vertical-align: sub;
}

div.whyAreWeAskingQuestionButton {
  margin-right: $margin-tiniest;
}

.youngerThanSixteenInfo {
  background-color: $vesta-light-blue;
  color: $white;
  border-radius: 6px;
  padding: $padding-tiny;
  display: none;
  margin-bottom: $margin-small;

  &.active {
    display: flex;
  }

  p {
    color: $white;
  }

  .learnMoreLink {
    color: $white;
    text-decoration: underline;
    font-size: $font-size-smallText;
    cursor: pointer;
  }

  .icon {
    width: $icon-size * 1.2;
    height: $icon-size * 1.2;
    min-width: $icon-size * 1.2;
    margin-right: $margin-tiny;
    margin-top: $margin-tiniest;
    filter: brightness(0) invert(1);
  }
}

.whyText {
  color: $vesta-pink;
}

.header {
  position: relative;

  p, h1, h3, span {
    font-size: $font-size-small;
  }
}

.right > div {
  margin-top: $margin-tiny;
}

.userFormContent > div {
  margin-top: $margin-tiny;
}

.userFormContent {
  margin-bottom: $margin-spacer;
}

.right .visible {
  opacity: 0;
}

.right .errorMessage {
  width: 100%;
  color: $color-form-error;
  min-height: $font-size-large;
}

.inlineForm > div {
  display: flex;
  align-items: center;
}

.finalDetails {
  margin-bottom: $margin-tiny;
}

.highlightedText {
  color: $vesta-pink;
  font-size: $font-size-medium;
  font-family: FuturaDemi;
}

.locationInput label {
  color: $dark-grey;
  font-size: $font-size-small;
}

.questionIcon {
  width: $icon-size;
  height: $icon-size;
  margin-left: $margin-tiny;
}

.border {
  background-color: $border-color;
  height: 1px;
  margin: $margin-large 0px;
}

.footerButtons {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: $margin-largest * 2;

  .consentButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $margin-small;
  }

  .reportButtonContainer {
    margin-bottom: $margin-tiny;
  }

  .saveAndDownloadJournal {
    height: $button-height-small;
  }

  .dontSaveButtonContainer {
    height: $button-height-small;
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
  .personalInfoContent {
    display: flex;
    padding: 0px;
  }

  .howOldQuestion {
    margin-bottom: $margin-large * 2;
  }

  .left {
    flex: 1;
    position: relative;
    padding-right: $padding-largest;
  }

  .right {
    flex: 1;
    padding-left: $padding-largest;
  }

  .personalInfoContainer {
    max-width: $general-page-max-width;
    padding: 0px $padding-large;
    display: flex;
    flex-direction: column;
    padding-top: $padding-tiny;
  }

  .youngerThanSixteenInfo {
    margin-bottom: $margin-largest * 1.4;
    opacity: 0;
    display: flex;

    &.active {
      opacity: 1;
    }
  }

  div.iconSize {
    width: $icon-size-large;
    height: $icon-size-large;
    margin-left: $margin-tiny;
  }

  .finalDetails {
    margin: $margin-large 0;
    padding: 0px;
    font-size: $font-size;
  }

  .locationInput label {
    font-size: $font-size;
  }
}
