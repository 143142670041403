@import 'scss/base.scss';

$padding-right-multichoice: 2.5rem;

.checkbox {
  display: none;
}
.checkbox + span {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #b8b8b8;
  cursor: pointer;
  padding:4px
}
.checkbox:checked + span {
  background: $vesta-pink;
  border: none;
}

.checkbox + span {
  margin-right: 4px;
}

.multiChoiceContainer {
  padding: $padding-tiny;
  padding-right: $padding-right-multichoice;

  width: $full;
  border-width: 1px;
  border-bottom-width: 0;
  border-color: #b8b8b8;
  border-style: solid;
  display: flex;
  justify-content: space-between;
}

.multiChoiceContainer:last-of-type {
  border-bottom-width: 1px;
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
  .multiChoiceStyle {
    & + label {
      font-size: $font-size;
      padding: 0px $padding-tiny;
      height: $input-min-height;
      line-height: $input-min-height;
    }
  }
}

.required,
.errorMessage {
  color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
}

.errorField {
  border-color: $color-form-error;
}
