@import "scss/base.scss";

.passwordModalForm {
  text-align: center;

  .body {
    margin-bottom: $margin-small;
    text-align: left;

    &.highlightedText {
      color: $vesta-pink;
      font-weight: bold;
    }
  }

  .passwordInput {
    flex-direction: row;
    align-items: center;

    label {
      margin-right: $margin-tiny;
    }
  }
}

.savePasswordButtonContainer {
  display: flex;
  justify-content: center;
  .savePasswordButton {
    width: 70%;
  }
  margin-top: $margin-small;
  margin-bottom: $margin-medium;
}