@import "scss/base.scss";

.resourcesCenterPage {
    margin: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.homeResourcesCard {
    width: $full;
    margin-top: $margin-large;

    .frozenRowContent {
        margin: auto;
        align-items: center;
        padding: $padding-small $padding-tiny;

        .inputOptions {
            flex: 1;
            margin-top: $margin-small;
        }

        .resourcesInputContainer {
            margin-top: $margin-tiny;
            display: flex;
            align-items: center;
        }
    }
}

.frozenRow {
    width: 100%;
    background-color: $vesta-light-blue;
    top: 0;
    
    .frozenRowContent {
        margin: auto;
        align-items: center;
        padding: $padding-small $padding-tiny;

        .inputOptions {
            flex: 1;
            margin-top: $margin-small;
        }

        .resourcesInputContainer {
            margin-top: $margin-tiny;
            display: flex;
            align-items: center;
        }
    }
}

.searchInput {
    margin: 0;
    padding: 0;
    margin-right: $margin-tiny;
}

.resourcesListContainer {
    border-radius: 0px;
    padding-top: 0px;
    background: none;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: auto;
    padding-bottom: $padding-largest;
    flex: 1;
    overflow: scroll;
    width: 100%;
}

.attributionContainer {
    display: flex;
    align-items: center;

    .attributionIcon {
        width: $icon-size * 6;
        height: $icon-size * 3;
        filter: brightness(0) invert(1);
    }

    .attribution {
        color: $white;
        text-decoration: underline;
        margin: $margin-tiniest 0;
    }
}


.resourceCategoryContainer {
    margin-top: $margin-largest;
}

.categoryList {
    display: none;
}

.resourcesInputContainer {
    margin-top: $margin-small;
    flex: 1;
    position: relative;

    .searchInput label {
        color: $white;
    }
}

.categoryHeaderContainer {
    display: flex;
    align-items: center;
}

.flagIcon {
    width: $icon-size;
    height: $icon-size;
    margin-right: $margin-tiniest;
}

.backButton {
    margin-left: $margin-tiny;
    margin-right: $margin-largest * 2;

    p, i {
        color: $white;
    }

    i {
        border-color: $white;
    }
}

.header {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: $logo-height-small;
}

.headerImage {
    height: $logo-height-small;
    width: $logo-height-small;
    align-self: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    max-width: $full;
    filter: brightness(0) invert(1);

    &.vestaLogo {
        background-image: url('../../assets/images/pngs/vesta_logo.png');
    }

    &.homeIcon {
        background-image: url('../../assets/svgs/icon-home.svg');
    }
}

.distanceOptions {
    margin: 0;
    text-align: center;
    
    p {
        color: white;
        font-size: $font-size;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {     
    .resourcesCenterPage {
        margin: auto;
        height: 100vh;
    }

    .homeResourcesCard {
        @include cardStyle;
        .frozenRowContent {
            max-width: $article-max-width;
            margin: auto;
            width: calc(#{$full} - #{$padding-small} * 2);
            display: flex;
            align-items: center;
            padding: $padding-tiny 0 $padding-small;

            .inputOptions {
                margin-top: 0;
            }
        }

        .attributionIcon {
            filter: initial;
        }

        p, .searchInput label, .attribution {
            color: inherit;
            font-size: $font-size;
        }
    }
    
    .frozenRow {
        .frozenRowContent {
            max-width: $article-max-width;
            margin: auto;
            width: $article-max-width;
            display: flex;
            align-items: center;
            padding: $padding-tiny 0 $padding-small;

            .inputOptions {
                margin-top: 0;
            }
        }
    }
    
    .searchInput {
        margin: 0;
        padding: 0;
    }
    
    .resourcesListContainer {
        padding-top: $margin-small;
        padding-bottom: $padding-largest;

        .resourcesList {
            margin: auto;
            max-width: $article-max-width;
        }
    }
    
    .resourceCategoryContainer {
        margin-top: $margin-largest;
    }
    
    .categoryList {
        display: none;
    }
    
    .resourcesInputContainer {
        margin: 0px;
    }
    
    .categoryHeaderContainer {
        display: flex;
        align-items: center;
    }
    
    .flagIcon {
        width: $icon-size;
        height: $icon-size;
        margin-right: $margin-tiniest;
    }
    
    .backButton {
        margin-left: $margin-tiny;
        margin-right: $margin-largest * 2;
    
        p, i {
            color: $white;
        }
    
        i {
            border-color: $white;
        }
    }
    
    .header {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: $logo-height-small;
    }
    
    .headerImage {
        height: $logo-height-small;
        width: $logo-height-small;
        align-self: center;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        max-width: $full;
        filter: brightness(0) invert(1);
    
        &.vestaLogo {
            background-image: url('../../assets/images/pngs/vesta_logo.png');
        }
    
        &.homeIcon {
            background-image: url('../../assets/svgs/icon-home.svg');
        }
    }
}
