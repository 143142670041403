@import "scss/base.scss";

.homePage {
    background: $light-grey;
    min-height: 100vh;
    padding-bottom: $padding-bottom-tos-pp-offset;
}

.whoIsVestaLink {
    display: flex;
    align-items: center;
    position: absolute;
    top: $margin-largest;
    right: 0;

    .whoIsVestText {
        font-family: FuturaDemi;
        font-weight: bold;
    }

    .questionIcon {
        width: $icon-size;
        height: $icon-size;
        margin: 0 $margin-tiny;
    }
}

.borderBlue {
    background-color: #6A788E;
    width: calc(100% + #{$padding-tiny * 2});
    position: absolute;
    bottom: -$margin-small;
    top: calc(#{$margin-largest} * 3);
    z-index: 0;
    border-radius: $radius $radius 0px 0px;
    margin: -$padding-tiny;
}

.rightHomeCards, .leftHomeCards {
    display: flex;
    margin-top: $margin-large;

}

.leftCards, .rightCardsContent {
    max-width: $home-cards-max-width;
    margin: auto;
    z-index: 999;
    position: relative;
}

.rightCards {
    padding-top: $padding-tiny;
    position: relative;
}

.cardsContainer {
    padding-top: $padding-tiny;
    position: relative; 
}

.homeContent {
    max-width: $general-page-max-width;
    height: $full;
    margin: auto;
    padding: 0px $padding-tiny;
    position: relative;
}

.homeHeader {
    font-size: $font-size-large;
    font-weight: normal;
    text-align: center;
}

.reportCard {
    color: $vesta-light-blue;
    flex: $half;
    margin-right: $margin-tiny;
    max-width: $half;
    margin-bottom: $margin-large;

    h2, p, span {
        color: $vesta-light-blue;
    }
}

.resourcesCard {
    color: $vesta-pink;
    flex: $two-third;
    margin-right: $margin-tiny;
    max-width: $two-third;

    h2, p, span {
        color: $vesta-pink;
    }
}

.feedbackCard {
    color: $vesta-brown;
    flex: $one-third;
    max-width: $one-third;

    h2, p, span {
        color: $vesta-brown;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .cardsContainer { 
        padding-top: $padding-medium;
    }

    .rightCards {
        padding-top: $padding-medium;
    }
    
    .homeContent {
        padding: 0px $padding-medium;
    }
    
    .reportCard {
        margin-right: $margin-medium;
    }
    
    .resourcesCard {
        margin-right: $margin-medium;
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .borderBlue {
        display: none;
    }

    .homeHeader {
        text-align: left;
    }

    .cardsContainer {
        padding-top: $padding-large;
        display: flex;
    }

    .leftCards, .rightCards {
        flex: 1;
        padding: 0px;
        margin: 0px;
    }

    .leftCards {
        padding-right: calc(#{$padding-largest}*2);
    }

    .reportCard {
        flex: $full;
        margin-right: inherit;
        max-width: inherit;
    }
    
    .resourcesCard {
        flex: $full;
        margin-right: inherit;
        max-width: inherit;
    }
    
    .feedbackCard {
        flex: $full;
        margin-right: inherit;
        max-width: inherit;
    }

    .rightHomeCards, .leftHomeCards {
        flex-direction: column;
        margin: 0px;
    }
    
    .feedbackCard {
        margin-top: $margin-large;
    }

    .accessSavedReport {
        min-height: $home-card-min-height-small;
    }

    .whoIsVesta {
        display: flex;
    }
}
