@import "scss/base.scss";

.reportBreak {
    background-color: $vesta-light-blue;
    min-height: 100vh;
    padding-bottom: $padding-bottom-tos-pp-offset;

    header {
        div {
          filter: brightness(0) invert(1);
        }
    }

    p {
        color: #F5F5F5;
    }

    .left {
        overflow: hidden;

        width: $full;
        min-width: $full;
        max-width: $full;

        padding: 0px;

        .breakIllustration {
            max-height: 150px;
        }
    }

    .right {
        width: $full;
    }

    .reportContent {
        background-color: $vesta-light-blue;

        h1, h3, span {
            font-size: $font-size;
        }
    }
}

.breakIllustration {
    width: 100%;
    height: $illustration-break-size;
    margin: $margin-tiny 0;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .reportPage {
        background: transparent;
        @include grayPanel;
    }

    .reportBreak {
        background: transparent;

        header {
            div {
              filter: none;
            }
        }

        @include rightGrayPanel;

        .left {
            @include transitionTime;
            overflow: hidden;
    
            width: $half;
            min-width: $half;
            max-width: $half;
    
            padding: 0px;
    
            .breakIllustration {
                max-height: inherit;
            }
        }
    
        .right {
            width: $half;
        }
    }

    .reportContent {
        display: flex;
        padding: 0px;
    }

    .left {
        flex: 1;
        position: relative;
        padding-right: $padding-largest;
        padding-bottom: $padding-largest;
        @include transitionTime;

        width: $report-form-width;
        min-width: $report-form-width;
        max-width: $report-form-width;
        margin: 0px;
    }

    .right {
        flex: 1;
        padding-left: $padding-largest;

        overflow: visible;
        padding-left: 0;
        position: fixed;
        right: 0;
        width: calc(100% - #{$report-form-width});
    }

    .startReportLeftSection {
        display: none;
    }

    .startReportRightSection {
        display: block;
    }

    .headerTitleContainer {
        .title {
            position: absolute;
            bottom: 0;
            top: 0;
            left: $icon-size;
            margin-left: $margin-small;
        }
    }
}

/* Media Queries */
// Page height scroll check
@media only screen and (max-height:$page-height-max) and (min-width:$desktop-min-breakpoint) {

}
