@import "scss/base.scss";

.attachments {
    text-align: right;
}

.required, .errorMessage {
  color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
}

.errorField {
  border-color: $color-form-error;
}

.attachment {
    display: flex;
    align-items: center;
    margin: $margin-tiny 0;
    text-align: left;
    overflow-wrap: anywhere;

    p {
        font-size: $font-size-small;
    }
}

.paperclipWhiteIcon {
    width: $icon-size;
    height: $icon-size;
    display: inline-block;
    vertical-align: sub;
}

.fileIcon {
    width: $icon-size;
    height: $icon-size;
    display: inline-block;
    margin-right: $margin-tiny;
}

.closeIcon {
    width: $icon-size-small;
    height: $icon-size-small;
    display: inline-block; 
    margin-left: $margin-tiny;
}

.smGroupIcon {
    height: $icon-size-larger;
    width: $sm-group-icon-width;
    background-position: left;
}

.attachmentButtonContainer {
    text-align: left;
     
    > * {
        margin-bottom: $margin-tiny;
    }
}

.attachmentsList {
    min-width: $two-third;
    padding-right: $padding-largest;
}

.attachmentsContainer {
    display: flex;
}

.attachFileButton {
    margin: 0px;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .paperclipWhiteIcon {
        width: $icon-size-large;
        height: $icon-size-large;
        vertical-align: sub;
    }
}

