@import 'scss/base.scss';

.attachReport {
  margin-bottom: $margin-tiny;
}

.exclamationReceiveSupport {
  display: flex;
  align-items: center;
  gap: $margin-tiny;
  color: $vesta-pink;
  font-size: $font-size;
  margin-top: $margin-tiny;
  margin-bottom: $margin-largest;
}

.iconSize {
  width: $icon-size;
  height: $icon-size;
  display: inline-block;
  vertical-align: sub;
}

.highlightedText {
  color: $vesta-pink;
  font-size: $font-size;
  font-family: FuturaDemi;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
  div.iconSize {
    width: $icon-size-large;
    height: $icon-size-large;
  }
}
