@import "scss/base.scss";

$swatchOption: 26px * 1.5;

.pictureChoiceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pictureStyling {
    position: relative;
    background-size: 26px;
    width: $swatchOption;
    height: $swatchOption;
    margin: 0 $padding-tiniest;
  }

  .pictureLabel {
    font-size: $font-size-tiniest;
    text-align: center;
    width: $full;
  }

  .radio:checked,
  .radio:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  .radio:checked + label,
  .radio:not(:checked) + label
  {
    cursor: pointer;
  }
  
  .radio + label {
    border-radius: $radius-rounded-corners;
  }
  
  .radio:checked + label {
    background-color: $vesta-pink;
    border-color: $vesta-pink;
    color: $white;
  }
  
  .radio:checked + label + label {
    background-color: $vesta-pink;
    border-color: $vesta-pink;
    color: $white;
    padding-bottom: $margin-tiniest;
  }

  .radio:checked:disabled + label {
    background-color: $color-background-disabled;
    border-color: $color-background-disabled;
  }
}


/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
  .pictureChoiceContainer {
    .pictureLabel {
      font-size: $font-size-small;
    }
  }
}
