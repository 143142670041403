@import "scss/base.scss";

.startReport {
    @include fadeInFromBottom;

    opacity: 0;
    text-align: center;
    margin-bottom: $margin-tiny;
}

.reportPrevNextContainer {
    text-align: center;
    position: relative;

    button, a {
        min-width: $button-min-width;
    }
}

.illustration {
    display: none;
    width: $full;
    height: $illustration-size-small;
    margin: $margin-tiny 0;
    margin-top: calc(-#{$header-max-height}/2);
}

.prevButton, .nextButton, .submitButton {
    max-width: $break-content-max-width;
    margin-left: auto;
    margin-right: auto;
} 

.prevButton, .submitButton {
    margin-bottom: $margin-medium;
}

.nextButton {
    margin-bottom: $margin-tiniest;
    
    &.completeButton {
        height: $button-height-small;
        line-height: $button-height-small;
        min-height: $button-height-small;
        width: fit-content;
    }

    span {
        color: $white;
    }

    a {
        display: inline-flex;
        align-items: center;
        width: initial;
    }
}

div.whiteCheckIcon {
    width: $icon-size;
    height: $icon-size;
    margin-right: $margin-tiniest;
    display: inline-block;
    margin-top: -5px;
}

.title {
    display: flex;
    align-items: center;
    margin-bottom: $margin-small;
}

.downloadButtonContainer {
    width: $full;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .illustration {
        display: block;
        height: $illustration-size;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .reportPrevNextContainer {
        margin-top: $margin-large * 4;
        min-height: calc(100vh - #{$margin-large} * 4);
    }
}
