@import "scss/base.scss";
@import "scss/icons.scss";

.cardHome {
    @include cardStyle;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px $padding-tiny $padding-small;

    .cardContent {
        flex: 1;
        justify-content: left;
        display: flex;
        flex-direction: column;
        width: $full;

        h2 {
            font-size: $font-size-small;
        }
    
        p, span {
            font-size: $font-size-small;
        }
    }
}

.arrowRight {
    height: $font-size-large;
    width: $font-size-large;
    display: none;
}

.cardIcon {
    width: $home-icon-size;
    height: $home-icon-size;
    margin-top: $home-icon-offset;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .cardHome {
        flex-direction: row;
        align-content: center;
        padding: $padding-tiny;
    
        .cardContent {
            h2 {
                font-size: inherit;
            }

            p, span {
                font-size: $font-size;
                max-width: calc(#{$full} - #{$icon-size});
            }
        }

        &.reportStart, &.resources {
            min-height: $home-card-min-height-large;
        }

        &.feedback {
            h2 {
                font-size: $font-size-small;
            }

            p, span {
                font-size: $font-size-small;
                max-width: calc(#{$full} - #{$icon-size});
            }

            .cardIcon {
                width: $home-icon-size-small;
                height: $home-icon-size-small;
                margin-top: inherit;
                margin-right: $margin-tiny;
            }
        }
    }

    .cardIcon {
        width: $home-icon-size-large;
        height: $home-icon-size-large;
        margin-top: inherit;
        margin-right: $margin-tiny;
    }

    .arrowRight {
        display: block;
    }
}
