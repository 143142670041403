// Medium base
:root {
    font-size: 18px;
}

// Font sizes
$font-size-h2: 1.5rem;
$font-size-h3: 1.325rem;
$font-size-h4: 1.25rem;
$font-size-h5: 1.125rem;
$font-size: 1rem;
$font-size-smallText: 0.85rem;
$font-size-microText: 0.5rem;

$font-size-h2-mobile: 1.5rem;
$font-size-h3-mobile: 1.325rem;
$font-size-h4-mobile: 1.25rem;
$font-size-h5-mobile: 1.125rem;
$font-size-mobile: 1rem;
$font-size-smallText-mobile: 0.85rem;
$font-size-microText-mobile: 0.5rem;

$padding-tiniest: 0.2rem;
$padding-tiny: 0.625rem;
$padding-small: 1.25rem;
$padding-medium: 1rem;
$padding-large: 1.5rem;
$padding-largest: 2rem;
$padding-print: 3rem;

$radius-tiniest: 0.5rem;
$radius-tiny: 1rem;
$radius-small: 1.25rem;
$radius: 1.5rem;
$radius-large: 3rem;

$margin-tiniest: 0.2rem;
$margin-tinier: 0.3rem;
$margin-tiny: 0.625rem;
$margin-small: 1.25rem;
$margin-medium: 1rem;
$margin-large: 1.5rem;
$margin-largest: 2rem;
$margin-print: 3rem;

$font-size-tiniest: 0.45rem;
$font-size-tiny: 0.6rem;
$font-size-small: 0.75rem;
$font-size-medium: 0.85rem;
$font-size: 1rem;
$font-size-large: 1.25rem;
$font-size-largest: 2rem;

$border-size-small: 1px;
$border-size-medium: 2px;
$border-size-large: 3px;

$icon-size-tiny: 0.5rem;
$icon-size-small: 0.75rem;
$icon-size: 1rem;
$icon-size-medium: 1.1875rem;
$icon-size-large: 1.25rem;
$icon-size-larger: 1.5rem;

$button-size-small: 1.75rem;
$button-size-medium: 2rem;
$button-size-large: 2.5rem;

// Media Query Sizing
$tablet-min-breakpoint: 768px;
$desktop-min-breakpoint: 1024px;
$tablet-max-breakpoint: calc(#{$desktop-min-breakpoint} - 1px);

// Percentage sizing
$full: 100%;
$half: calc(100%/2);
$one-third: calc(100%/3);
$two-third: calc(2 * 100% / 3);
$one-fourth: calc(100%/4);
$one-sixth: calc(100%/6);
$one-eight: calc(100%/8);

$modal-width-small: 400px;
$modal-width-medium: 450px;
$modal-max-width: $two-third;
$modal-max-width-large: 560px;

$report-form-width: calc(50% + 80px); // 7 columns of a 12 column system
$padding-link-button: 0.45rem;

$input-min-height-small: 2rem;
$input-min-height: 2.3rem;
$button-height-small: 2rem;
$button-height: 2.3rem;
$button-min-width: 170px;
$button-path-option-width: 15rem;

//Pixel sizing
$logo-height-print: 48px;
$logo-width-print: 132px;
$logo-height: 50px;
$logo-height-small: 30px;
$complexion-image-small: 50px;
$complexion-image: 80px;
$article-header-height-small: 210px;
$article-header-height-medium: 320px;
$article-header-height-large: 430px;
$article-max-width: 812px;
$home-cards-max-width: 812px;
$report-page-max-width: 812px;
$general-page-max-width: 1240px;
$general-page-max-width-tablet: 768px;
$resource-list-image-small: 130px;
$resource-list-image-large: 180px;
$home-icon-size-small: 60px;
$home-icon-size: 75px;
$home-icon-size-large: 90px;
$home-icon-offset: -($home-icon-size/2);
$home-card-min-height-large: 160px;
$home-card-min-height-small: 130px;
$radius-rounded-corners: 8px;
$illustration-size-large: 750px;
$illustration-size: 17rem;
$illustration-size-medium: 400px;
$illustration-size-small: 300px;
$illustration-size-smaller: 250px;
$illustration-size-tiny: 200px;
$illustration-break-size: 640px;
$phone-number-input-max-width: 90px;
$icon-textarea-max-width: 70px;
$header-max-height: 180px;
$break-content-max-width: 450px;
$page-height-max: 768px;
$path-image-height: calc(100vh / 2.5);
$sm-group-icon-width: 225px;
$path-image-height-large: 180px;
$padding-bottom-tos-pp-offset: $padding-largest * 3;

$margin-spacer: 80px;
$margin-spacer-large: 130px;