@import "scss/base.scss";

.updateQuestions {
    @include fadeInFromBottom;

    opacity: 0;

    > * {
        margin-bottom: $margin-tiny;
    }

    .acknowledgementOfConsent {
    display: flex;
    align-items: center;
    margin: 0;
    text-align: center;
    font-family: FuturaDemi;
    line-height: 1rem;
    }
    
    .consentButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $margin-large;
    margin-top: $margin-large;
    }

}

div.iconSize {
    width: $icon-size;
    height: $icon-size;
    margin-left: $margin-tiniest;
}

.acknowledgementOfConsentLink {
    margin: $margin-largest 0;
    text-align: center;

    button {
        font-family: FuturaDemi;
        color: $vesta-dark-pink;
        margin: 0;
    }
}

.updateCancelButtonsContainer {
    text-align: center;
    display: flex;
    flex-direction: column;

    .cancelButton {
        margin-top: $margin-tiny;
    }
}

.consentToggles {
    margin-top: $margin-large;
    
    > * {
        margin-bottom: $margin-tiny;
    }
}


// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    div.iconSize {
        width: $icon-size-large;
        height: $icon-size-large;
        margin-left: $margin-tiny;
    }
}

