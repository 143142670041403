@import 'scss/base.scss';

.footer {
    margin: auto;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    // border-top: solid 1px $medium-grey;
    padding: $margin-tiny 0px;
    //background-color: $white;
    z-index: 999;

    &.reportBreak {
        background-color: transparent;

        a {
            color: $white;

            &:first-child {
                border-right: solid 1px $white;
            }
        }
    }

    a {
        padding: 0px $margin-tiny 0px;
        color: $vesta-pink;
        font-size: $font-size-medium;

        &:hover {
            text-decoration: underline;
        }

        &:first-child {
            border-right: solid 1px $vesta-pink;
        }
    }
}

.headerImage {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
    height: $logo-height-print;
    width: $logo-width-print;
    align-self: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    max-width: $full;
    margin-left: 0;
    margin-top: 0;
    padding-right: auto;
    padding-bottom: auto;
   // filter: brightness(0) invert(1);

    &.vestaLogo {
        background-image: url('../../../assets/svgs/vesta-logo-full-print.svg');
    }

    &.homeIcon {
        background-image: url('../../../assets/svgs/icon-home.svg');
    }
}

.headerText {
    margin-left: auto;
    right: 0;
    color: $color-text;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .footer {
        &.reportForm {
            width: calc(100% - #{$report-form-width});
            left: auto;
            z-index: -1;
        }

        &.reportBreak {
            width: $half;
            left: auto;
        }
    }
}

@media print {
    .footer {
        position: fixed;
        top: 0;
    }

}