@import "scss/base.scss";
@import "scss/forms.scss";
@import "scss/icons.scss";
@import "../../../Common/SingleInputCard/styles.module.scss";

.flex {
   display: flex;
   align-items: baseline;
}

.timeRangeContainer {
    .dash {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
}
