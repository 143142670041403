@import "scss/base.scss";

div.lockIcon {
  width: $logo-height;
  height: $logo-height;
  margin: auto;
}

.modalContent {
  text-align: center;

  p {
    text-align: center;
    margin: $margin-tiniest;
    margin-bottom: $margin-medium;

    a {
      font-family: 'FuturaHeavy';
      color: $vesta-pink;
    }
  }

  button {
    margin: auto;
    width: auto;
    min-width: $one-third;
  }


}

