@import 'scss/base.scss';

  .load {
    display:flex;
    justify-content: center;
    margin: $margin-largest auto $margin-tiny;
  }
  
  .load div {
    width: 20px;
    height: 20px;
    background-color: $vesta-light-blue;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 0 5px;
    animation-name: up-and-down;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  
  .load .secondCircle {
    animation-delay: 0.1s;
  }
  .load .thirdCircle {
    animation-delay: 0.4s;
  }

  .loadingMessage {
    margin: 0;
    text-align: center;
    margin-bottom: $margin-largest;
  }


  @keyframes up-and-down {
    to {
      opacity: 0.2;
      transform: translateY(-20px);
      -webkit-transform: translateY(-20px);
      -moz-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      -o-transform: translateY(-20px);
  }
    
  }