@import "scss/base.scss";

.contactLawEnfOrSuppCenModal {
  width: $full;
  max-width: $full;
  min-width: $full;

  transform: none;
  position: absolute;
  overflow: scroll;
  height: $full;
  margin: auto;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  padding: $padding-small 0;

  .title {
    color: $vesta-light-blue;
    margin-top: $margin-small;
  }

  .highlightedText {
    color: $vesta-pink;
    font-weight: bold;
  }

  .contactOptionContainer {
    .contactOption {
      width: $full;
      padding: 0 $padding-small;

      .contactImage {
        width: 200px;
        height: 200px;
        margin: auto;
      }

      h5 {
        margin-bottom: $margin-tiny;
        text-align: center;
      }
      
      .body {
        padding-bottom: $padding-tiny;
      }
    }
  }
}


/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) {
  .contactLawEnfOrSuppCenModal {
    min-width: inherit;
    max-width: 900px;
    width: 100%;
    height: 585px;
    padding: $padding-small;

    .title {
      color: $vesta-light-blue;
    }
  
    .highlightedText {
      color: $vesta-pink;
      font-weight: bold;
    }
  
    .contactOptionContainer {
      display: flex;
      justify-content: center;
  
      .contactOption {
        width: 50%;
        padding: 0 $padding-large;
  
        &:first-child {
          border-right: solid 1px $border-color;
        }

        &:nth-child(2) {
          border-right: solid 1px $border-color;

        }
  
        .contactImage {
          width: 200px;
          height: 200px;
          margin: auto;
        }
  
        h5 {
          margin-bottom: $margin-tiny;
        }
        
        .body {
          padding-bottom: $padding-tiny;
        }
      }
    }
  }
}