@import "scss/base.scss";

.learnMoreLink {
  display: inline-flex;
  align-items: center;

  .learnMoreText {
    font-weight: bold;
    text-decoration: underline;

    &.grey {
      color: $dark-grey;
      text-decoration-color: $dark-grey;
    }
    &.pink {
      color: $vesta-pink;
      text-decoration-color: $vesta-pink;
    }
    &.white {
      color: $white;
      text-decoration-color: $white;
    }
    &.blue {
      color: $vesta-medium-blue;
      text-decoration-color: $vesta-medium-blue;
    }
  }

  .externalLinkIcon {
    width: $icon-size-small;
    height: $icon-size-small;
    margin-left: $margin-tiniest;
  }
}
