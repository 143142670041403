@import "scss/base.scss";

.attachmentFailedModalModal {
  .title {
    margin-bottom: $margin-small;
  }
  
  .okayButton {
    margin-top: $margin-small;
  }
}
