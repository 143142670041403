@import "scss/base.scss";

.downloadModal {
  padding: $padding-large;
  padding-bottom: 70px;

  .writeStoryImage {
    height: $illustration-size / 1.5;
    width: $illustration-size / 1.5;
    margin: $margin-tiny auto;
  }
}

.body {
  display: flex;
  flex-direction: column;

  p {
    width: $full;
    text-align: center;
  }

  .highlightedText {
    color: $vesta-pink;
    font-family: FuturaHeavy;
  }

  .downloadLinkButton {
    margin-top: -$margin-tiniest;
    margin-bottom: $margin-medium;
    font-size: $font-size;
  }

  .continueButton {
    padding-left: $padding-large;
    padding-right: $padding-large;
   
    margin-top: $margin-largest;
    margin-bottom: $margin-tiny;
  }
}


/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
  .downloadModal {
    min-width: $modal-width-small;
    max-width: $modal-width-small;
  }
}
// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .downloadModal {
    min-width: $modal-width-small;
    max-width: $modal-width-small;
  }
}