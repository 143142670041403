@import "scss/base.scss";
@import "scss/forms.scss";
@import "scss/icons.scss";

.locationsContainerWithQuestion,
.locationsContainerWithoutQuestion {
    width: $full;

    label {
        margin-right: 0;
    }
    
    .inputContainer {
        display: flex;
        box-shadow: none;
        align-items: center;

        .locationIcon {
            height: $icon-size;
            width: $icon-size;
            margin-left: calc(-#{$margin-tiny} + -#{$icon-size});
            margin-right: $margin-tiny;
            flex: initial;
            position: relative;
        }

        .locationInput{
            flex: 1;
        }

        .arrowIcon {
            height: $font-size-large;
            width: $font-size-large;
        }
    }
}

.locationsLabelContainer {
    max-width: fit-content;
}

.locationsContainerWithoutQuestion {
    display: flex;
    .inputContainer {
        flex: 1;
    }
    .locationsLabelContainer {
        order: 2;
        line-height: $half;
    }
}

.errorField {
    input {
        border-color: $color-form-error;
    }
}

.greyLocationIcon {
    height: $font-size-large;
    width: $font-size-large;
    flex: initial;
    margin-right: $margin-tiny;
}

.locationSuggestionListItem {
    display: flex;
    border-bottom: solid $border-size-small $light-grey;

    &:last-child {
        border: none;
    }
}


.locationSuggestions {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: $margin-tiny 0px;
    border-radius: $radius-rounded-corners;

    .locationList {
        flex: 1;
        justify-content: left;
        display: flex;
        flex-direction: column;
        width: $full;
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        .locationSuggestionListItem {
            padding: $padding-tiny;
        }

        p span {
            font-size: $font-size-small;
        }
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .locationsContainerWithQuestion {
        label {
            color: $dark-grey;
        }
    }

    .inputContainer {
        .locationIcon {
            height: $icon-size-large;
            width: $icon-size-large;
            margin-left: calc(-#{$margin-tiny} + -#{$icon-size-large});
            margin-right: $margin-tiny;
            flex: initial;
        }
    }
}

.required, .errorMessage {
  color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
}

.errorField {
  border-color: $color-form-error;
}