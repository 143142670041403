@import 'scss/base.scss';

.checkbox {
  display: none;
}
.checkbox + span {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #b8b8b8;
  cursor: pointer;
  padding:4px
}
.checkbox:checked + span {
  background: $vesta-pink;
  border: none;
}

.checkbox + span {
  margin-right: 4px;
}