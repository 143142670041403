@import "scss/base.scss";

  .multiChoiceStyle {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  
    & + label {
      cursor: pointer;
      display: inline-block;
      white-space: nowrap;
      padding: 0px $padding-tiny;

      height: $input-min-height-small;
      border: solid $border-size-small $border-color;
      line-height: $input-min-height-small;
      background-color: $white;

      border-radius: $radius-large;
      margin-right: $margin-tiny;
    }
    
    // Box focus
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
    
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
    
    &:checked + label {
      background-color: $vesta-pink;
      color: white;
    }
  }
  
  .multiChoicesContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .multiChoiceContainer {
    margin-top: 10px;
  }

  /* Media Queries */
  // Tablet
  @media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

  }

  // Desktop
  @media only screen and (min-width:$desktop-min-breakpoint) {
    .multiChoiceStyle {
      & + label {
        font-size: $font-size;
        padding: 0px $padding-tiny;
        height: $input-min-height;
        line-height: $input-min-height;
      }
    }
  }

  .required, .errorMessage {
    color: $color-form-error;
    font-size: $font-size-small;
    font-style: italic;
  }

  .errorField {
    border-color: $color-form-error;
  }