@import "scss/base.scss";

.radioLabel {
    margin-right: 15px;
    display: inline-block;
    flex: 1;
}

.radioContainer {
    display: inline-block;
}

.radio:checked,
.radio:not(:checked) {
    position: absolute;
    left: -9999px;
}
.radio:checked + label,
.radio:not(:checked) + label
{
    position: relative;
    padding: 0px 15px;
    cursor: pointer;
    display: inline-block;
}

.radioContainer:first-of-type .radio + label{
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
}

.radioContainer:last-of-type .radio + label {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
}

.radio + label {
    height: 50px;
    border: solid 1px $border-color;
    line-height: 50px;
}

.radio:checked + label {
    background-color: $vesta-pink;
    border-color: $vesta-pink;
    color: white;
}

.booleanOptions {
    flex-wrap: nowrap;
    display: flex;
}

.toggleStyle {
    display: flex;
    align-items: center;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .radioLabel {
        font-size: $font-size;
    }
}

.required, .errorMessage {
  color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
  margin-bottom: $margin-large;
}

.errorField {
  border-color: $color-form-error;
}
