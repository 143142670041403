@import "scss/base.scss";

.reportPageContent {
    margin-top: 30px;

    > * {
        margin-bottom: $margin-medium;
    }
}

.pageFooter {
    float: right;
}

.highlightedText {
    font-weight: bold; 
    color: $white;
  }

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) {
    .reportPage {
        margin: 0px;
    }

    .reportPageContent {
        opacity: 0;
        @include fadeInFromBottom;
    }
}