@import "scss/base.scss";

$password-label-width: 75px;

div.iconSize {
  width: $icon-size-large;
  height: $icon-size-large;
  min-width: $icon-size-large;
  margin: auto $margin-tiny;
}

div .passwordRule {
  display: flex;
  text-align: left;
  margin: $margin-tiny auto;
}

ul.passwordRulesList {
  padding-left: 0;
  margin-left: $password-label-width;
}