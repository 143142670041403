@import "scss/base.scss";

.locationSelectContainer {
  width: $full;
  padding-top: $padding-tiny;
}

.locationSelectFlagText {
  margin-top: $margin-tiny;
}

.locationInputFlagText {
  margin-top: $margin-spacer;
}
