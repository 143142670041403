@import "scss/base.scss";

.basicReportInfoField {

    width: 50%;
    margin-bottom: $margin-tiny;
    white-space: pre-wrap;
    div.label {
        display: flex;
        align-items: center;
    }
    
    .dotText {
        -webkit-text-security: disc;
        -moz-text-security: disc;
        max-width: 90%;
        overflow: hidden;
    }
    
    div.iconSize {
        width: $icon-size;
        height: $icon-size;
        margin-right: $margin-tiniest;
    }

}

@media only screen and (max-width: 980px) {

}
