@import "scss/base.scss";

.offenderFace {
  width: $complexion-image;
  height: $complexion-image;

  border-radius: 8px;
  background-color: $white;
  line-height: 100px;
  align-self: center;

  position: relative;
  width: 90px;
  height: 120px;

  .pictureStyling {
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;

    &.hair {
      z-index: 1;
      top: 12px;
      height: 62px;
      right: 2px;
    }

    &.face {
      height: 83px;
      top: 27px;
    }
  }
}

.errorMessage {
  color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
  .offenderFace {
    float: right;
    margin-top: -140px;
  }
}
