@import "scss/base.scss";
@import "scss/forms.scss";
@import "scss/icons.scss";

.dateTime {
    width: $full;
}

.singleDateTime {
    display: flex;
    align-items: center;

    > p {
        margin-right: $margin-tiny;
    }
}

.dateRangeType {
    margin-bottom: $margin-tiny;
}


.errorField {
    p {
        color: $color-form-error;
    }
  }


/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .dateTime {
        label {
            color: $dark-grey;
        }
    }

    .singleDateTime {
        > p {
            margin-right: $margin-small;
        }
    }
}

.required, .errorMessage {
  color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
}