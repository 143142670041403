@import "scss/base.scss";

.reportSubmitSuccessPageContainer {
  max-width: $general-page-max-width;
  padding: 0px $padding-small;
  margin: auto;
}

.reportSubmitSuccessContent {
    margin-top: $margin-largest;
    .phoenixImage {
        width: $illustration-size-tiny;
        height: $illustration-size-tiny;
        margin: auto;
        margin-bottom: $margin-tiny;
      }
}

.submitSummary {
    border: solid 1px $border-color;
    border-radius: $radius-rounded-corners;
    padding: $padding-medium;
    margin: $margin-large 0;

    .summaryPoint {
        display: flex;
        margin-bottom: $margin-medium;
    }

    .links {
        margin-top: $margin-large;
        margin-bottom: $margin-large;
        margin-left: $margin-large;
        span, p {
            font-size: $font-size-medium;
            margin-bottom: $margin-tiny;
        }
        span {
            text-decoration: underline;
        }
        .externalLinkIcon {
            width: $icon-size-small;
            height: $icon-size-small;
            margin-left: $margin-tiniest;
            display: inline-block;
        }
    }
}

div.iconSize {
    width: $icon-size;
    height: $icon-size;
    min-width: $icon-size;
    min-height: $icon-size;
    margin-right: $margin-tiniest;
    margin-top: $margin-tiniest;
}

.left {
    text-align: center;

    .reportKeyHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $margin-large;
    }

    .body {
        text-align: center;
        margin-top: $margin-large;
    }
}

.right {
    .subtitle {
        margin-top: $margin-tiny;
        font-size: $font-size-smallText;
    }
}

.viewResourcesCTA {
    margin-bottom: $margin-tiny;
}

.saveAndDownloadJournalButton {
    margin-top: $margin-large;
    margin-bottom: $margin-tiniest;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
  .reportSubmitSuccessContent {
    display: flex;
    padding: 0px;
  }

  .reportSubmitSuccessContent {
    .phoenixImage {
        width: $illustration-size-tiny;
        height: $illustration-size-tiny;
        margin: auto;
        margin-bottom: $margin-tiny;
      }
}

  .left {
    flex: 1;
    position: relative;
    padding-right: $padding-largest;
    border-right: solid 1px $border-color;
  }

  .right {
    flex: 1;
    padding-left: $padding-largest;
  }

  .accessSupportContainer {
    max-width: $general-page-max-width;
    padding: 0px $padding-large;
    display: flex;
    flex-direction: column;
    padding-top: $padding-tiny;
  }
  
}
