@import "scss/base.scss";

.left {
    @include fadeInFromBottom;
    padding-top: $padding-small;

    opacity: 0;

    > * {
        margin-bottom: 15px;
    }

    .acknowledgementOfConsent {
        display: flex;
        align-items: center;
    }

}

.right {
    width: $full;
    padding-bottom: $padding-bottom-tos-pp-offset;
}

.label {
    font-size: $font-size-small;
    margin-bottom: $margin-tiniest;
} 

.basicInfo {
    display: flex;
    flex-wrap: wrap;

    .basicInfoField {
        width: 50%;
    }
}

.section {
    padding: 20px 0; //placeholder to help visually distinguish sections for now
}

div.iconSize {
    width: $icon-size;
    height: $icon-size;
}

.buttonIcon {
    width: $icon-size;
    height: $icon-size;
    display: inline-block;
    vertical-align: sub;
    margin-right: $margin-tiny;
}

div.reportInformationImage {
    width: $illustration-size;
    height: $illustration-size-small;
}

.updateSubmittedStepHeader {
    display: flex;
    p:first-child {
        flex: 1;
    }
}

.pageFooter {
    float: right;
}

.reviewReportPage {
    max-width: $general-page-max-width;
    padding: 0px $padding-tiny;
    margin: auto;

    p, h1, h3, span, div {
        font-size: $font-size-small;
    }
}

.vestaIcon {
    height: $logo-height-small;
    width: $logo-height-small;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $margin-large;
}

.reviewButtonsContainer {
    text-align: center;

    > *:first-child {
        margin-bottom: $margin-tiny;
    }
}

.reviewReportPage {
    .title {
        display: flex;
        align-items: center;
        font-size: $font-size; 
    }
}

.titleContainer {
    display: flex;

    h1 {
        flex: 1;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .vestaIcon {
        height: $logo-height;
        width: $logo-height;
    }

    .reviewReportPage {
        display: flex;
        max-width: $general-page-max-width;
        padding: 0px $padding-tiny;
        margin: auto;
    
        p, h1, h3, span, div {
            font-size: $font-size;
        }
    }

    .left {
        flex: 1;
        position: relative;
        padding-right: $padding-largest;
        padding-bottom: $padding-largest;
        width: $half;
    }
    
    .right {
        flex: 1;
        padding-left: $padding-largest;
        border-left: solid 1px $border-color;
        width: $half;
    }

    .titleContainer {
        .viewReportButton {
            display: none;
        }
    }
}