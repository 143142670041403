@import "scss/base.scss";

.notSureOption {
  display: flex;
}

.pictureOptionsContainer {
  display: flex;
  margin: 0;
}


/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
  .imageStyling {
    width: $complexion-image;
    height: $complexion-image;
  }
}

.required, .errorMessage {
  color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
}

.errorField {
  border-color: $color-form-error;
}
