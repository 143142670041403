@import "scss/base.scss";


.progressBarContainer {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    box-shadow: 8px -4px 10px 0 rgba(0, 0, 0, 0.12);
    bottom: $margin-small * 1.3;
    position: absolute;
    width: 100%;

    .progressCircleContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 50%;
            bottom: 0;
            margin: auto;
            width: 100%;       
            height: 3px;
            background-color: $border-color;
            z-index: -1;
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            left: -100%;
            bottom: 0;
            margin: auto;
            width: 100%;        
            height: 3px;
            background-color: $border-color;
            z-index: -1;
        }

        &:first-child {
            &::after, &::before {
                display: none;
            }
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        .progressCircle {
            width: 15px;
            height: 15px;
            background-color: $border-color;
            border-radius: $full;
        }

        &.active {
            &::before {
                background-color: $vesta-pink;
            }

            &::after {
                background-color: $vesta-pink;
            }

            .progressCircle {
                background-color: $vesta-pink;
            }
        }
    }

}

/* Media Queries */
// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .progressBarContainer {
        display: flex;
    }
}