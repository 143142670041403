@import "scss/base.scss";

html {
    width: 100%;
    height: auto !important;
    overflow: auto !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

body {
    width: 100%;
    height: auto !important;
    overflow: auto !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

.reportBreak {
    background-color: $vesta-light-blue;
    min-height: 100vh;
    padding-bottom: $padding-bottom-tos-pp-offset;

    header {
        div {
          filter: brightness(0) invert(1);
        }
    }

    p {
        color: #F5F5F5;
    }

    .left {
        overflow: hidden;

        width: $full;
        min-width: $full;
        max-width: $full;

        padding: 0px;

        .breakIllustration {
            max-height: 150px;
        }
    }

    .right {
        width: $full;
    }

    .reportContent {
        background-color: $vesta-light-blue;

        h1, h3, span {
            font-size: $font-size;
        }
    }
}

.breakIllustration {
    width: 100%;
    height: $illustration-break-size;
    margin: $margin-tiny 0;
}

.reportPrintRefWrapper {
    display: none;
}

/* Media Queries */
.printReportPage {
    padding: $padding-print $padding-large $padding-print $padding-large ;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    color: black;
}

.reportBreak {
    background: transparent;

    header {
        div {
          filter: none;
        }
    }

    @include rightGrayPanel;

    .left {
        @include transitionTime;
        overflow: hidden;
        overflow-y: auto;

        width: $half;
        min-width: $half;
        max-width: $half;

        padding: 0px;

        .breakIllustration {
            max-height: inherit;
        }
    }

    .right {
        width: $half;
    }
}

.reportContent {
    display: flex;
    padding: 0px;
}

.left {
    flex: 1;
    position: relative;
    padding-right: $padding-largest;
    padding-bottom: $padding-largest;
    @include transitionTime;

    width: $report-form-width;
    min-width: $report-form-width;
    max-width: $report-form-width;
    margin: 0px;
}

.right {
    flex: 1;
    padding-left: $padding-largest;

    overflow: visible;
    padding-left: 0;
    position: fixed;
    right: 0;
    width: calc(100% - #{$report-form-width});
}

.startReportLeftSection {
    display: none;
}

.startReportRightSection {
    display: block;
}

.headerTitleContainer {
    .title {
        position: absolute;
        bottom: 0;
        top: 0;
        left: $icon-size;
        margin-left: $margin-small;
    }
}

/* Media Queries */
// Page height scroll check
@media only screen and (max-height:$page-height-max) and (min-width:$desktop-min-breakpoint) {

}

@media print {

    @page {
        size: 1292px 1672px;
        margin: 0.75in;
    }

    @page {
        @top-right {
            margin: 1in;
            content: "Page " counter(pageNumber);
        }

    }

}

.pageBreak {
    position:relative;
    margin-top: auto;
    margin-left: auto;
    right: 0;
    bottom: 0;
    page-break-before: always;
    // This is the padding on the top of the page break
    padding-top: $margin-print; 
}