@import "scss/base.scss";

.locationSuggestionListItem {
    display: flex;
    border-bottom: solid $border-size-small * 1 $vesta-light-blue;
    padding: $padding-tiny;

    .header {
        .listingTitle {
            display: flex;
            align-items: center;
            margin-bottom: $margin-tiny;
            flex-direction: column;
            align-items: flex-start;

            .viewMoreButton {
                color: $white;
                background-color: $vesta-light-blue;
                align-items: center;
                padding: $padding-tiny;
                border-radius: $radius-large;
            }

            .headerTitle {
                color: $vesta-light-blue;
                flex: 1;
                margin-bottom: $margin-small;
            }
        }

        margin-bottom: $margin-tiny;
        color: $vesta-light-blue;

        
        .description {
            max-height: 65px;
            overflow: hidden;
        }
    }

    &.listingOpen {
        .header{
            .description {
                max-height: fit-content;
            }
        }
    }

    .showMoreButton {
        color: $vesta-light-blue;
    }

    .directions {
        color: $vesta-light-blue;
    }

    .descriptionOverflowHidden {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .directionsWebsiteContact {
        display: flex;
        justify-content: center;
        border-top: dashed $border-size-small $border-color;
        padding-top: $padding-tiny;
        flex-wrap: wrap;

        p, a, div {
            font-size: $font-size-medium;
        }

        .contactContainer {
            text-align: center;
            flex: 1;
            width: $half;
            white-space: normal;
            max-width: $half;
            margin: $margin-tiny;
            min-width: calc(#{$half} - #{$margin-tiny} * 2);

            .mapIcon {
                width: $icon-size-large * 1.2;
                height: $icon-size-large * 1.2;
                margin: auto;
                margin-bottom: $margin-tiniest;
            }
        }

        .directions {
            width: $half;
            text-align: center;
        }

        .website {
            width: $half;
            text-align: center;
            word-wrap: break-word;
        }
    }

    &:last-child {
        border: none;
    }
}

.locationIcon {
    display: none;
}

.locationListing {
    flex: 1;
    width: 100%;
    margin-top: $margin-tiny;
}

div.resourcePageNav {
    text-align: center;
    box-shadow: none;
    margin-top: $margin-large;
    padding-bottom: $padding-large;
    position: initial;
    transform: none;
    box-shadow: none;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .locationSuggestionListItem {
        display: flex;
        border-bottom: solid $border-size-small * 1 $vesta-light-blue;
        padding: $padding-medium;
    
        .header {
            .listingTitle {
                display: flex;
                align-items: center;
                margin-bottom: $margin-tiny;
                flex-direction: row;
    
                .viewMoreButton {
                    color: $white;
                    background-color: $vesta-light-blue;
                    align-items: center;
                    padding: $padding-tiny;
                    border-radius: $radius-large;
                    margin-right: $margin-tiny;
                }
    
                .headerTitle {
                    color: $vesta-light-blue;
                    flex: 1;
                    margin: 0;
                }
            }
    
            margin-bottom: $margin-tiny;
            color: $vesta-light-blue;
    
            .description {
                max-height: 65px;
                overflow: hidden;
            }
        }
    
        .showMoreButton {
            color: $vesta-light-blue;
        }
    
        .directions {
            color: $vesta-light-blue;
        }
    
        .descriptionOverflowHidden {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
        .directionsWebsiteContact {
            display: flex;
            justify-content: center;
            border-top: dashed $border-size-small $border-color;
            padding-top: $padding-tiny;
    
            p, a, div {
                font-size: $font-size-medium;
            }
    
            .contactContainer {
                text-align: center;
                flex: 1;
                max-width: $one-fourth;
                white-space: normal;
                margin: 0 $margin-tiny;
                min-width: auto;
    
                .mapIcon {
                    width: $icon-size-large * 1.2;
                    height: $icon-size-large * 1.2;
                    margin: auto;
                    margin-bottom: $margin-tiniest;
                }
            }
    
            .directions {
                width: $one-eight;
                text-align: center;
            }
    
            .website {
                width: $one-fourth;
                text-align: center;
                word-wrap: break-word;
            }
    
            .contactInfo {
                display: flex;
                width: calc(#{$half} + #{$one-eight});
        
                .phoneNumber {
                    display: flex;
                    color: $vesta-light-blue;
                    padding: auto;
                }
            }
        }
    
        &:last-child {
            border: none;
        }
    }
    
    .locationIcon {
        display: block;
        height: $icon-size * 1.2;
        width: $icon-size * 1.2;
        min-height: $icon-size * 1.2;
        min-width: $icon-size * 1.2;
        margin-right: $margin-tiny;
        margin-top: $margin-tiny;
    }
    
    .locationListing {
        flex: 1;
        width: 100%;
    }
    
}
