@import "scss/base.scss";

.howToAnswerIfUnsure {
  color: $color-secondary;
  border-radius: 6px;
  display: flex;

  .thoughtBubbleIcon {
    width: $icon-size * 1.5;
    height: $icon-size * 1.5;
    min-width: $icon-size * 1.5;
    margin-right: $margin-tiny;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust:exact !important;
    color: $color-text;
  }
}

@media print {
  .howToAnswerIfUnsure {
    p {
      color: $white;
    }
  }
}

.highlightedText {
  font-weight: bold; 
  color: $white;
}
