@mixin cardStyle {
    background: #FFFFFF;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);
    border-radius: 20px; 
    transition: all 0.2s;

    &.gradient {
        background-image: linear-gradient(90deg, $gradient-blue 0%, $gradient-pink 100%);
        box-shadow: 0 3px 8px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);
        border-radius: 20px;

        p {
            color: white;
        }
    }

    &.blue {
        background-color: $vesta-light-blue;

        p {
            color: white;
        }
    }

    &.pink {
        background-color: $vesta-pink;

        p {
            color: white;
        }
    }

    &:hover:not(.blue):not(.gradient):not(.pink) {
        transition: all 0.2s;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    } 
}

@mixin grayPanel {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        background: #F5F5F5;
        box-shadow: 6px 3px 12px -6px rgba(0,0,0,0.23), 6px 3px 10px -6px rgba(0,0,0,0.16);
        position: absolute;
        display: inline-block;
        vertical-align: top;
        transition: all 0.2s;
    }

    &:before {
        transition: all 0.2s;
        width: $report-form-width;
    }
}

@mixin rightGrayPanel {

    background: $vesta-light-blue;
    
    p{
       color : #F5F5F5;
       padding-left: $padding-tiny;
       padding-bottom: $padding-small;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        background: #F5F5F5;
        box-shadow: 6px 3px 12px -6px rgba(0,0,0,0.23), 6px 3px 10px -6px rgba(0,0,0,0.16);
        position: absolute;
        display: inline-block;
        vertical-align: top;
        transition: all 0.2s;
    }

    &:before {
        @include transitionTime;
        width: $half;
    }

    &:after {
        content: '';
    }

}