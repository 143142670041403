@import "scss/base.scss";

.surveyContainer {
    padding-top: $padding-tiny;
    position: relative; 
    display: flex;
    flex-direction: column;
}

.left {
    width: $full;
    .header {
        color: $vesta-dark-pink;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
    .subHeader {
        font-size: $font-size-h5;
    }
}

.right {
    width: $full;
}

.surveySelectContainer {
    display: flex;
    flex-direction: column;
    margin-top: $margin-large;

    .ageSelect {
        width: $full;

    }
    .classificationSelect {
        margin-top: $margin-large;
        width: $full;
    }
}

.vestaResourceToggle,
.paidSupportToggle,
.freeSupportToggle {
    margin-top: $margin-large;
}

.toggleBooleanLabel {
    margin-right: $margin-largest;
}

.bottomButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: $margin-spacer;

    .submitButton {
        margin-bottom: $margin-tinier;
        width: $button-min-width;
    }
}

.textArea {
    margin-top: $margin-largest;
}

.location {
    margin-top: $margin-large;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .surveyContainer { 
        padding-top: $padding-medium;
        align-items: center;
    }
    .left, .right {
        width: 5 * $one-sixth;
    }

    .surveySelectContainer {
        flex-direction: row;

        .ageSelect {
            margin-right: $margin-tiny;
            width: $one-third;
        }

        .classificationSelect {
            width: $two-third;
            margin-top: 0;
        }
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .surveyContainer {
        padding-top: $padding-large;
        flex-direction: row;
    }

    .left {
        width: $half;
        margin-right: $margin-largest;

        .subHeader {
            margin-right: $margin-large;
        }
    }

    .right {
        width: $half;
        margin-left: $margin-largest;
    }

    .freeSupportToggle {
        margin-top: $margin-spacer-large;
    }    

    .surveySelectContainer {
        flex-direction: row;

        .ageSelect {
            padding-right: $margin-tiny;
            width: $one-third;
        }
        
        .classificationSelect {
            width: $two-third;
            margin-top: 0;
        }
    }
}
