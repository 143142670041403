@import "scss/base.scss";

.header {
    font-family: FuturaDemi;
    margin-top: $margin-tiny;
}

.subheader {
    margin-top: $margin-tiny;
    margin-bottom: $margin-large;
}

.lockIcon {
    width: $logo-height;
    height: $logo-height;
    margin: auto;
}

.loginModalContent {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .passwordInput {
        display: flex;
        flex-direction: row;
        width: $full;
        align-items: center;

        label {
            margin-right: $margin-tiny;
        }
    }
}

.required, .alert, .errorMessage {
    color: $color-form-error;
    font-size: $font-size-small;
    font-style: italic;
  }
  
.errorField {
    input {
        border-color: $color-form-error;
    }
}

p.reportKey {
    font-size: 3rem;
    margin-top: 15px;
    font-size: 2.5rem;
    font-family: FuturaDemi;
    color: $vesta-pink;
}

.accessReportButton {
    margin-top: $margin-large;
}