@import "scss/base.scss";

.SeekSupportModal {
  
  .highlightedText {
    color: $vesta-pink;
    font-weight: bold;
  }

  .header{
    color: $vesta-light-blue;
    font-weight: bold;
  }
}
