@import 'scss/base.scss';

header {
    display: flex;
    flex-direction: row;

    &.hasBackArrow {
        flex-direction: column;
    }

    .header {
        &.hasBackArrow {
            width: auto;
        }
    }
}

.attention {
    color: $vesta-light-blue;
}

.header {
    display: flex; 
    padding: $margin-largest 0;
    align-items: center;

    .homeIcon {
        margin-right: $margin-tiny;
    }

    .backLink {
        display: flex;
        align-items: center;
        margin: 0;
        color: $vesta-light-blue;
        margin-top: $margin-tiny;

        .arrowIcon {
            margin-right: $margin-tiny;
            i { 
                border-color: $vesta-light-blue; 
            }
        }
    }
}

.vestaLogo {
    height: $logo-height-small;
    width: $logo-height-small;
    align-self: center;
    background-image: url('../../assets/images/pngs/vesta_logo.png');
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    max-width: $full;

    &.hasBackButton {
        flex: 0 0 $one-third;
    }
}

.headerImage {
    height: $logo-height-small;
    width: $logo-height-small;
    align-self: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    max-width: $full;

    &.vestaLogo {
        background-image: url('../../assets/images/pngs/vesta_logo.png');
    }

    &.homeIcon {
        background-image: url('../../assets/svgs/icon-home.svg');
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .header {
        p {
            font-size: $font-size;
        }
    }

    .headerLogo {
        margin-top: $margin-large;
        margin-bottom: $margin-large;
        height: $logo-height;
        width: $logo-height;
    }

    .lightTheme {
        .backLink {
            color: $white;
            .arrowIcon {
                i { 
                    border-color: $white; 
                }
            }
        }
    }
}
