@import "scss/base.scss";

.thoughtBlurbContainer {
  color: $white;
  border-radius: 6px;
  padding: $padding-tiny;
  display: flex;

  p {
    color: $white;
  }

  .thoughtBubbleIcon {
    width: $icon-size * 1.5;
    height: $icon-size * 1.5;
    min-width: $icon-size * 1.5;
    margin-right: $margin-tiny;
  }

  &.blue {
    background-color: $vesta-light-blue;
  }
  &.pink {
    background-color: $vesta-dark-pink;
  }
}

