@import "scss/base.scss";

$icon-size-feeling: 2rem;
$icon-size-feeling-large: 2.4rem;

.experience {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $margin-tiny;
}

div.feelingIconSize {
    width:  $icon-size-feeling;
    height: $icon-size-feeling;
}

.scale {
    display: flex;
    justify-content: space-between;
    width: $full;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    div.feelingIconSize {
        width:  $icon-size-feeling-large;
        height: $icon-size-feeling-large;
    }
    .experience {
        margin-bottom: $margin-small;
    }
}
