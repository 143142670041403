@import "scss/base.scss";


.modal {
    background: white;
    border: solid $border-size-small $border-color;
    outline: none;

    transform: none;
	position: absolute;
	overflow: scroll;
	height: $full;
	margin: auto;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	padding: $padding-small;
	width: $full;
	max-width: $full;
    min-width: $full;

    .title {
        margin-bottom: $margin-small;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal::-webkit-scrollbar {
 display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.modal {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.centerTitle {
    text-align: center;
    font-size: $font-size;
    margin-bottom: $margin-small;
}

.closeIcon {
    width: $icon-size;
    height: $icon-size;
    position: absolute;
    right: $margin-medium;
    top: $margin-medium;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .modal {
        max-width: $modal-max-width;
        min-width: $modal-max-width;
        height: auto;
        top: $half;
        left: $half;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .modal {
        top: $half;
        left: $half;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        border-radius: $radius-rounded-corners;
        padding: $padding-small;
        position: absolute;
        max-width: calc(#{$full} - #{$margin-small});
        min-width: calc(#{$full} - #{$margin-small});
        height: auto;

        max-width: $modal-max-width-large;
        min-width: $modal-max-width-large;
    }
}
