@import "scss/base.scss";

label {
    margin-right: $margin-tiny;
}

.addReportKeysModalContent {
    text-align: center;

    .passwordInput {
        flex-direction: row;
        align-items: center;
        margin-top: $margin-small;
        margin-bottom: $margin-small;

        label {
            margin-right: $margin-tiny;
        }
    }

    .reportKeyInput {
        flex-direction: row;
        align-items: center;
        margin-top: $margin-small;

        label {
            margin-right: $margin-tiny;
        }
    }
}