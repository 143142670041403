@import "./base/colors.scss";
@import "./base/sizes.scss";
@import "./base/helpers.scss";
@import "./base/anim.scss";
@import "./base/components.scss";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
}

p, div, a, span, link, button, input, textarea, label, select {
  font-family: FuturaBook;
  font-size: $font-size-mobile;
  color: $dark-grey;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: 'FuturaHeavy';

  &.subheader {
    font-family: 'FuturaBook';
    font-weight: 100;
  }

  span {
    font-family: 'FuturaHeavy';
    margin: 0;
  }
}

h2 {
  font-size: $font-size-h2-mobile;
}

h3 {
  font-size: $font-size-h3-mobile;
}

h4 {
  font-size: $font-size-h4-mobile;
}

h5 {
  font-size: $font-size-h5-mobile;
}

p, span, h1, button, div {
  margin: 0px;

  &.smallText {
    font-size: $font-size-smallText-mobile;
  }

  &.microText {
    font-size: $font-size-microText-mobile;
  }

  &.attention {
    font-family: FuturaHeavy;
  }

  &.vestaBlue {
    color: $vesta-light-blue;
  }

  &.vestaPink {
    color: $vesta-pink;
  }

  &.preline {
    white-space: pre-line;
  }
}

.textCenter {
  text-align: center;
}

.required, .alert, .errorMessage {
  color: $color-form-error;
}

.hide {
  display: none;
}

.greyed-out {
  color: $color-text-disabled !important;
}

.reportPage {
  background: $light-grey;
  min-height: 100vh;
  padding-bottom: $padding-bottom-tos-pp-offset;
}

.errorField {
  border-color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
}

.errorMessage {
    width: $full;
    color: $color-form-error;
    font-size: $font-size-mobile;
    margin-top: $margin-tiniest;
    font-style: italic;
}

.reportContainer {
  padding: 0px $padding-tiny;
  margin: auto;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
  .reportContainer {
    max-width: $general-page-max-width-tablet;
    padding: 0px $padding-medium;
    margin: auto;
  
    .reportContent {
      padding: 0 $padding-tiny;
    }
  }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .reportPage {
    height: 100vh;
  }

  .reportContainer {
    max-width: $general-page-max-width;
    padding: 0px $padding-large;
    margin: auto;
  
    .reportContent {
      padding: 0 $padding-tiny;
  
      .left {
        @include fadeInFromBottom;
        @include transitionTime;
      
        opacity: 0;
        text-align: left;
      }
    }
  }

  p, div, a, span, link, button, input, textarea, label, select {
    font-size: $font-size;
  }
  
  h2 {
    font-size: $font-size-h2;
  }
  
  h3 {
    font-size: $font-size-h3;
  }
  
  h4 {
    font-size: $font-size-h4;
  }
  
  h5 {
    font-size: $font-size-h5;
  }
  
  p {
    &.smallText {
      font-size: $font-size-smallText;
    }
  
    &.microText {
      font-size: $font-size-microText;
    }
  }
}