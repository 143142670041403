@import "scss/base.scss";

.submitReportModal {
  text-align: center;
  margin: auto;

  .phoenixImage {
    width: $illustration-size-small;
    height: $illustration-size-small;
    margin: auto;
    margin-bottom: $margin-tiny;
  }

  .body {
    margin: $margin-small 0;
  }

  .reportKeyHeader {
      margin: $margin-large 0;

      .reportKey {
        font-size: $font-size-largest;
        color: $vesta-pink;
        margin: 0;
      }

      p {
        font-size: $font-size-medium;
        max-width: 65%;
        margin: auto;
      }
  }

  .highlightedText {
    color: $vesta-pink;
    font-weight: bold;
  }

  button {
    margin: auto;
    width: auto;
    min-width: $one-third;
  }
}
