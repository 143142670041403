@import "scss/base.scss";

.sectionQuestionAnswers {
    .section {
        padding: 20px 0; //placeholder to help visually distinguish sections for now
    }
}

.s-sensory {
    flex: 1;
    padding: $padding-tiny;

    h2 {
        font-size: $font-size-small;
        &:hover {
            text-decoration: underline;
        }
    }
    
    p {
        font-size: $font-size-small;
    }
}

@media only screen and (max-width: 980px) {
    .s-sensory {
        flex: 1;
        padding: 0px $padding-large $padding-large 0px;
    
        h2 {
            font-size: $font-size;
        }
    
        p {
            font-size: $font-size-small;
        }
    }
}
