@import "scss/base.scss";

.surveyRequestModal {
  padding: $padding-largest;

  .writeStoryImage {
    height: $illustration-size / 1.5;
    width: $illustration-size / 1.5;
    margin: $margin-tiny auto;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .yesButton {
    width: fit-content;
    margin: $margin-small 0 $margin-tiny 0;
    padding: 0 $margin-large;
  }
  .noButton {
    margin: 0;
    height: 0;
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
  .surveyRequestModal {
    min-width: $modal-width-small;
    max-width: $modal-width-small;
  }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .surveyRequestModal {
    min-width: $modal-width-small;
    max-width: $modal-width-small;
  }
}