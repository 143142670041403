@import 'scss/base.scss';

.selectContainer {
    opacity: 100;

    select {
        
        &.errorField {
            border-color: $color-form-error;
        }

        width: 100%;
        height: $input-min-height;
        padding: $margin-tiniest;
        padding-left: $margin-tiny;
        border: 1px solid $border-color;
        border-radius: $radius-tiniest;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23CECECE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right $margin-tiny center;
        background-size: 1em;
    }
}