@import "scss/base.scss";
@import "scss/forms.scss";
@import "scss/icons.scss";
@import "../SingleInputCard/styles.module.scss";

.resourcesNearMe {
    padding: $padding-small 0 0;
    width: $full;

    label {
        color: $white;
    }
    
    .inputContainer {
        @include input-container;
        display: flex;
        margin: $margin-tiny 0 0;

        input {
            padding-right: $padding-tiny;
            padding-left: 0;
        }

        .locationIcon {
            height: $font-size-large;
            width: $font-size-large;
            flex: initial;
        }

        .locationInput{
            flex: 1;
        }

        .arrowIcon {
            height: $font-size-large;
            width: $font-size-large;
        }
    }
}

.locationIcon {
    height: $font-size-large;
    width: $font-size-large;
    flex: initial;
    margin-right: $margin-tiny;
}

.locationSuggestionListItem {
    display: flex;
    border-bottom: solid $border-size-small $light-grey;

    .header {
        color: $vesta-light-blue;
    }

    .showMoreButton {
        color: $vesta-light-blue;
    }

    .directions {
        color: $vesta-light-blue;
    }

    .website {
        color: $vesta-light-blue;
    }

    .directionsWebsiteContact {
        display: flex;
        font-weight: 600;

        .directions {
            margin: 4px;
        }

        .website {
            margin: 4px;
        }

        .contactInfo {
            display: flex;
            margin: 4px;
    
            .phoneNumber {
                color: $vesta-light-blue;
            }
        }
    }

    &:last-child {
        border: none;
    }
}

.resourceSuggestionsContainer {
    display: block;
}

.resourceSuggestions {
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: $margin-tiny 0px;
    border-radius: $radius-rounded-corners;
    position: absolute;
    border: solid 1px $border-color;
    top: 75px;
    opacity: 0;

    .resourceList {
        flex: 1;
        justify-content: left;
        display: flex;
        flex-direction: column;
        width: $full;
        list-style-type: none;
        padding: 0px;
        margin: 0px;

        .locationSuggestionListItem {
            padding: $padding-tiny;
        }

        p span {
            font-size: $font-size-small;
        }
    }

    &.show {
        opacity: 1;
    }
}

.errorField {
    color: $white;
    padding: 4px;
    text-align: center;
    font-size: large;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .resourcesNearMe {
        label {
            color: $dark-grey;
        }
    }
}
