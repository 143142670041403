@mixin input-container() {
    flex: 1;
    display: flex;
    background: $white;
    border-radius: $radius-rounded-corners;
    align-items: center;
    padding: 0px $padding-tiny;
    min-height: $input-min-height-small;

    .inputTextField {
        flex: 1;
    }

    input {
        flex: 1;
        -webkit-appearance: none;
        outline: none;
        background: none;
        border: none;
        font-size: $font-size-small;
    }

    // Desktop
    @media only screen and (min-width:$desktop-min-breakpoint) { 
        min-height: $input-min-height;
    }
}

@mixin toggleStyle() {}


