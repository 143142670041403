/*
Try to utilize variables as much as possible.  It's one of the best things about a CSS preprocessor like Sass and it makes updates much easier
*/

//theme defaults
$color-primary: #787878;
$color-secondary: #b1df95;
$color-alert: #ff0000;
$color-success: #b1df95;
$color-form-error: #D90038;

//  colour background
$color-background: #ffffff;
$color-background-secondary: #551d00;
$color-highlight: #b1df95;
$color-box-shadow: #888888;
$color-background-footer: #b1df95;

// colour text
$color-text: #787878;
$color-text-secondary: #ffffff;
$color-text-link: #ffc7aa;
$color-text-disabled: #787878;


// buttons
$color-button-background: $color-background-secondary;
$color-button-text: $color-secondary;

$color-button-background-disabled: #c7c7c7;

// modals
$color-modal-background: #d48e6a;
$color-modal-text: #ffffff;

// header
$color-header-border: #b1df95;
$color-menu-text: #551d00;
$color-menu-background: #ffffff;

//flashtext
$color-flash-from: #d48e6a;


//Brand colours
$gradient-blue: #6A788E;
$gradient-pink: #B99FAB;
$light-grey: #F5F5F5;
$medium-grey: #CECECE;
$dark-grey: #6F6F6F;
$vesta-pink: #B57E91;
$vesta-light-blue: #6A788E;
$vesta-medium-blue: #536680;
$vesta-dark-blue: #253C5E;
$vesta-brown: #60494A;
$vesta-dark-pink: #8B304B;
$border-color: #D8D8D8;
$white: #FFFFFF;

// disabled
$color-background-disabled: $medium-grey;
$color-text-disabled: rgba($dark-grey, 0.7);