@import "scss/base.scss";

//amend styling including removing important and using a size from sizes.scss
.confirmationModalContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .check {
        width: $icon-size-larger * 2;
        height: $icon-size-larger * 2;
    }

    p {
        margin: $margin-large 0;
    }
    
}
