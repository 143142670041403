@import "scss/base.scss";
@import "scss/shared.scss";


.consentBody {
	margin-bottom: $margin-small;

	.privacyPolicyLink {
		@include textLink;
	}

	.externalLinkIcon {
	width: $icon-size-small;
    height: $icon-size-small;
    display: inline-block;
    margin-left: $margin-tiniest;
    cursor: pointer;
	}
}

div.consents {
	padding: $padding-tiniest $padding-medium;
	margin-bottom: $margin-small;

	.reportSummaryField {
		padding: 0 $padding-tiny;
		margin: 0;
	}

	h1 {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: $margin-tiny 0;
	}
}

.iDoNotConsent {
	text-align: center;
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
}