@import "scss/base.scss";

.note {
  h2 {
    font-size: $font-size-small;
  }
}

@media only screen and (max-width: 980px) {
}
