@import "scss/base.scss";

.updateSubmitted {
    @include fadeInFromBottom;

    opacity: 0;

    > * {
        margin-bottom: 15px;
    }

    .headerTitleContainer {
        margin-bottom: 0;
    }

    .acknowledgementOfConsent {
        display: flex;
        align-items: center;
    }

    .submittedDate {
        margin-left: $margin-tiniest;
    }

}

.updateReportContent {
    max-width: $general-page-max-width;
    margin: auto;
    padding: 0px $padding-tiny;

    h1, p, span {
        font-size: $font-size-small;
    }

    .reportInfoHeader {
        button {
            display: none;
        }
    }
}

.label {
    font-size: $font-size-small;
    margin-bottom: $margin-tiniest;
} 

.basicInfo {
    display: flex;
    flex-wrap: wrap;

    .basicInfoField {
        width: 50%;
    }
}

.section {
    padding: 20px 0; //placeholder to help visually distinguish sections for now
}

div.iconSize {
    width: $icon-size;
    height: $icon-size;
}

div.reportInformationImage {
    width: $illustration-size;
    height: $illustration-size-small;
    background-color: grey;
}

.updateSubmittedStepHeader {
    display: flex;
    p:first-child {
        flex: 1;
    }
}

.pageFooter {
    float: right;
}

.questionIcon {
    width: $icon-size;
    height: $icon-size;
    margin-left: $margin-tiny;
}

.title {
    display: flex;
    align-items: center;

    h1 {
       font-size: $font-size; 
    }
}

.titleContainer {
    display: flex;

    .title {
        flex: 1;
    }
}


@media only screen and (max-width: 980px) {

    .updateSubmitted {
        margin: 0px;
    }

    .label {
        font-size: $font-size;
    } 
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 

    .left {
        flex: 1;
        position: relative;
        padding-right: $padding-largest;
    }
    
    .right {
        flex: 1;
        padding-left: $padding-largest;
        border-left: solid 1px $border-color;
    }

    .updateReportContent {
        display: flex;
        padding: 0 $padding-tiny;

        p, h1, h3, span {
            font-size: $font-size;
        }
    }

    .headerTitleContainer {
        position: relative;

        p, h1, h3, span {
            font-size: $font-size;
        }
        
        .title {
            position: absolute;
            bottom: 0;
            top: 0;
            left: $icon-size;
            margin-left: $margin-largest;
        }
    }

    .titleContainer {
        .viewReportButton {
            display: none;
        }
    }
}


