
@import "../scss/base.scss";
@import "../scss/shared.scss";
@import "../scss/forms.scss";
@import "../scss/icons.scss";
@import "../scss/base/shapes.scss";
@import "../scss/pages/home.scss";
@import "../scss/pages/login.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: $color-background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $color-text;
}

.App-link {
  color: $color-text-link;
}

