@import "scss/base.scss";

div.sensoryIconSize {
    width: $icon-size-larger * 2;
    height: $icon-size-larger * 2;
    margin-right: $margin-medium;
}

.sensoryIconCard {
    background-color: $white;
    display: flex;
    margin: $margin-medium 0;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .sensoryIconCard {
        border-bottom: none;
        margin-bottom: $margin-tiny;
    }
}
