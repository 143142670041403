@import "scss/base.scss";

.reportPath {
  max-width: $general-page-max-width;
  margin: auto;
  text-align: center;
  padding-bottom: $padding-bottom-tos-pp-offset;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: $margin-largest;
    font-size: $font-size-h5;
    margin-bottom: $margin-large;
  }

  .saveExitButton,
  .nextButton {
    min-width: 275px;
    margin-top: $margin-tiny;
  }

  .mobileOnly {
    display: none;
    max-width: 325px;
    margin: $margin-tiny auto;

    &.active {
      display: block;
    }
  }

  .reportPathContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .pathsContainer {
      max-width: $two-third;
    }

    .pathCard{
      margin: auto;
      z-index: 999;
      position: relative;
      margin-bottom: $margin-large;
      
      .pathCardButton {
        padding: 30px;
        line-height: normal;
        font-size: $font-size-large;
        font-weight: bold;
        text-align: left;
        height: initial;
        max-height: 240px;
        min-width: 365px;
        color: $vesta-light-blue;

        &:hover {
          border-color: rgba($vesta-light-blue, 0.75);
          
          .buttonTitle{
            text-decoration: underline;
            text-decoration-thickness: $padding-tiniest;
            text-underline-offset: $margin-tiniest;
          }   
        }

        &.active{
          border-color: rgba($vesta-light-blue, 0.75);
         
          .buttonTitle {
            text-decoration: underline;
            text-decoration-thickness: $padding-tiniest;
            text-underline-offset: $margin-tiniest;
            margin: 0;
          }
  
          .arrow {
            display: block;
          }
        }

        .arrow {
          position: absolute;
          width: 15px;
          height: 15px;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 15px;
          display: none;
        }
      }

      .innerButtonText{
        margin-top: $margin-tinier;
        display: flex;
        color: $vesta-light-blue;
      }
    }

    .checkboxContainer {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }

    .checkbox {
      color: $vesta-pink;
      text-decoration: underline;
    }

    .stepAwayButton {
      margin-top: $margin-tiny;
      height: $input-min-height-small;
    }

    .left {
      flex: 1;
    }
    
    .right {     
      flex: 1;
      max-width: 375px;
      flex-direction: column;
      align-items: center;
      display: none;
    }

    .helpText {
      max-width: 500px;
    }
  }
}

.clickable {

  text-decoration: underline;
}

.clickable:hover {
  cursor:pointer ;
}

/* Media Queries */
// Tablet, Desktop
@media only screen and (min-width:$tablet-min-breakpoint) {
  .reportPath {
    text-align: center;
    padding-bottom: 0;
    height: 100vh;

    .title {
      margin-top: $margin-largest * 2;
      font-size: $font-size-h3;
    }

    .saveExitButton {
      margin-top: $margin-large;
    }

    .mobileOnly {
      display: none;
      &.active {
        display: none;
      }
    }
  
    .reportPathContent {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex: 1;
  
      .pathsContainer {
        display: flex;
        flex-direction: row;
        width: $full;
      }
  
      .left {
        flex: 1;
        width: 50%;
      }
      .right {     
        flex: 1;
        width: 50%;
        max-width: initial;
        display: flex;
      }
      .image {
        width: 300px;
        height: 300px;
        max-height: 240px;
        margin-bottom: $margin-large;
      }
      .bar{
        background-color: $medium-grey;
        width: 1px;
        height: 75%;
      }
    }
  }
}

