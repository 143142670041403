@import "scss/base.scss";

.phoneNumberContainer > *:not(div) {
  margin-right: $margin-tiny;
}

.phoneNumberContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .displayNone {
    display: none;
  }
} 

.required {
  color: $color-form-error;
}

.field {
  border: $border-size-small solid $border-color;
}

.textInput {
  background: $white;
  padding: $padding-tiniest 0px;
  -webkit-appearance: none;
  outline: none;
  border: solid $border-size-small $border-color;
  border-radius: $radius-rounded-corners;
  height: $input-min-height-small;
  flex: 1;
  width: $full;
  font-size: $font-size-small;
  color: $dark-grey;
  padding: $padding-tiniest;

  &.inlineTextInput {
    display: inline-block;
  }

  &.phoneNumber3Digits {
    display: inline-block;
    width: $phone-number-input-max-width;
    max-width: $phone-number-input-max-width;
  }

  &.phoneNumber4Digits {
    display: inline-block;
    flex: 1;
    margin-right: 0;
  }

  &::placeholder {
      font-style: italic;
      color: $medium-grey;
  }

  &.errorField {
    border-color: $color-form-error;
  }
}

  /* Media Queries */
  // Tablet
  @media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

  }

  // Desktop
  @media only screen and (min-width:$desktop-min-breakpoint) {
    .phoneNumberContainer > *:not(div) {
      margin-right: $margin-medium;
    }

    .textInput {
      height: $input-min-height;
      min-height: $input-min-height;
      font-size: $font-size;
    }

    .phoneNumberContainer {
      label {
        font-size: $font-size;
      }
    } 
    
  }
