@import "scss/base.scss";

.flagText {
    margin-top: $margin-large;
    margin-bottom: $margin-tinier;
}

.multiSelectHeader {
    margin-top: $margin-largest;
    margin-bottom: $margin-tiny;
}

.multiChoiceList {
    background-color: $white;
}

.centerJustifiedButtonContainer {
    display: flex;
    width: $full;
    .downloadButton {
        width: $full;
        margin-top: $margin-largest;
    }
}

.locationInput {
    input {
        font-size: $font-size;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .centerJustifiedButtonContainer {
        .downloadButton {
            min-width: fit-content;
            width: $two-third;
        }
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .centerJustifiedButtonContainer {
        .downloadButton {
            margin-top: $margin-largest;
            min-width: fit-content;
            max-width: $half;
        }
    }
}
