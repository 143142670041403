@import "scss/base.scss";

.radioContainer {
    display: inline-block;

    &:first-of-type {
        .radio + label {
           border-top-left-radius: $radius-large;
           border-bottom-left-radius: $radius-large;
        }
    }

   &:last-of-type {
        .radio + label {
            border-top-right-radius: $radius-large;
            border-bottom-right-radius: $radius-large;
        }
    }

    .radioLabel {
        margin-right: $margin-tiniest;
        display: inline-block;
    }

    .radio:checked,
    .radio:not(:checked) {
        position: absolute;
        opacity: 0;
    }
    .radio:checked + label,
    .radio:not(:checked) + label
    {
        position: relative;
        padding: 0px $padding-tiny;
        cursor: pointer;
        display: inline-block;
        white-space: nowrap;
        margin: 0px;
    }

    .radio + label {
        height: $input-min-height-small;
        border: solid $border-size-small $border-color;
        line-height: $input-min-height-small;
        background-color: $white;
    }

    .radio:checked + label {
        background-color: $vesta-pink;
        border-color: $vesta-pink;
        color: $white;
    }

    .disabled + label {
        color: $color-background-disabled;
    }
    .radio:checked:disabled + label {
        background-color: $color-background-disabled;
        border-color: $color-background-disabled;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .radioContainer {
        .radioLabel {
            margin-right: $margin-tiniest;
            display: inline-block;
        }
        .radio:checked + label,
        .radio:not(:checked) + label
        {
            padding: 0px $padding-tiny;
            cursor: pointer;
            display: inline-block;
            font-size: $font-size;
            white-space: nowrap;
        }
    
        .radio + label {
            height: $input-min-height;
            line-height: $input-min-height;
        }
    }
}

@media print {
    .radioContainer {
        .radio:checked + label {
            -webkit-print-color-adjust: exact !important;
            color-adjust: exact !important;
            print-color-adjust:exact !important;
            background-color: $vesta-medium-blue;
            border-color: $vesta-medium-blue;
            color: $white;
            border-width: $vesta-medium-blue;
        }
    }
}