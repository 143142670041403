@import "scss/base.scss";

$modal-width: $modal-width-medium;

.submitSurveyModal {
  padding: $padding-largest;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  .writeStoryImage {
    height: $illustration-size / 1.5;
    width: $illustration-size / 1.5;
    margin: 0 auto;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    color: $vesta-dark-pink;
  }

  .text {
    width: $two-third;
    margin-top: $margin-tiny;
    margin-bottom: $margin-medium;
  }

  .submitButton {
    width: fit-content;
    padding: 0 $margin-medium;
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
  .submitSurveyModal {
    min-width: $modal-width;
    max-width: $modal-width;
  }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .submitSurveyModal {
    min-width: $modal-width;
    max-width: $modal-width;
  }
}