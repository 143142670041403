body {
    width: 100%;
    height: 100%;
    overflow: scroll;
}

@mixin textLink {
    color: $vesta-pink;
    text-decoration: underline;
    cursor: pointer;
}

.text-link {
    @include textLink;
}

.react-datepicker__time-container {
    width: 130px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 130px;
}

.ReactModal__Overlay {
    z-index: 999;
}
