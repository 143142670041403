@import 'scss/base.scss';

.footer {
    margin: auto;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: solid 1px $medium-grey;
    padding: $margin-tiny 0px;
    background-color: $white;
    z-index: 999;

    &.reportBreak {
        background-color: transparent;

        a {
            color: $white;

            &:first-child {
                border-right: solid 1px $white;
            }
        }
    }

    a {
        padding: 0px $margin-tiny 0px;
        color: $vesta-pink;
        font-size: $font-size-medium;

        &:hover {
            text-decoration: underline;
        }

        &:first-child {
            border-right: solid 1px $vesta-pink;
        }
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .footer {
        &.reportForm {
            width: calc(100% - #{$report-form-width});
            left: auto;
            z-index: -1;
        }

        &.reportBreak {
            width: $half;
            left: auto;
        }
    }
}

@media print {
    .footer {
        position: fixed;
        bottom: 0;
        display: flex; 
        flex-direction: row;

        .footerLeft {
            text-align: left;
            left: 0;
            margin-right: auto;

            .footerName {
                font-weight: bold;
            }
        }

        .footerRight {
            text-align: right;
            right: 0;
            margin-left: auto;
            .link {
                font-weight: bold;
            }
        }
    }

}