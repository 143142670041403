@import "scss/base.scss";

.campusModalContent {
    .title {
        text-align: center;
        width: $two-third;
        margin-bottom: $margin-medium;
        position: relative;
        left: $one-sixth;
        white-space: pre-wrap;
    }

    button {
        margin-top: $margin-medium;
    }

    .body {
        text-align: left;
        margin-bottom: $margin-small;
    }
}

.resultButtons {
    display:flex;
    flex-direction:column; 
}

.image {
    width: 300px;
    height: 300px;
    margin: auto;
}