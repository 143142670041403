@import "scss/base.scss";

.loadingContainer {
  min-width: $button-min-width;
  margin: auto;
}

.loading, .loading:before, .loading:after {
	border-radius: 50%;
	background-color: $white;
	width: $icon-size-tiny;
	height: $icon-size-tiny;
	transform-origin: center center;
	display: block;
}
.loading {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
	background-color: rgba($white,1);
	opacity: 1;
	-webkit-animation: loadingAlpha 1s infinite linear;
	animation: loadingAlpha 1s infinite linear;
}
.loading:before, .loading:after {
	content: '';
  opacity: 0.25;
  position: absolute;
}
.loading:before {
	left: 30px;
	top: 0px;
	-webkit-animation: loadingAlphaBefore 1s infinite linear;
	animation: loadingAlphaBefore 1s infinite linear;
}
.loading:after {
	left: -30px;
	top: 0px;
	-webkit-animation: lodingAlphaAfter 1s infinite linear;
	animation: lodingAlphaAfter 1s infinite linear;
}
@-webkit-keyframes loadingAlpha {
	0% { opacity: 1; }
	33% { opacity: 0.25; }
	66% { opacity: 0.25; }
	100% { opacity: 1; }
}
@keyframes loadingAlpha {
	0% { opacity: 1; }
	33% {  opacity: 0.25; }
	66% { opacity: 0.25; }
	100% {  opacity: 1; }
}
@-webkit-keyframes loadingAlphaBefore {
	0% { opacity: 0.25; }
	33% { opacity: 1; }
	66% { opacity: 0.25; }
}
@keyframes loadingAlphaBefore {
	0% { opacity: 0.25; }
	33% { opacity: 1; }
	66% { opacity: 0.25; }
}
@-webkit-keyframes lodingAlphaAfter {
	33% { opacity: 0.25; }
	66% { opacity: 1; }
	100% { opacity: 0.25; }
}
@keyframes lodingAlphaAfter {
	33% { opacity: 0.25; }
	66% { opacity: 1; }
	100% { opacity: 0.25; }
}

.button {
  appearance: none;
  border: none;
  box-shadow: none;
  width: fit-content;
  outline: none;
  text-align: center;
  margin: auto;
  min-height: $button-size-small;
  height: $button-size-small;
  line-height: $button-size-small;
  border-radius: $radius-large;
  cursor: pointer;
  width: $full;
  position: relative;
  
  a {
    font-size: $font-size-small;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.skinnyPrimary {
    background-color: $vesta-pink;
    border-color: $vesta-pink;
    color: $white;
    height: $button-height-small;
    line-height: $button-height-small;

    &:hover {
      background-color: rgba($vesta-pink, 0.75);
      border-color: rgba($vesta-pink, 0.75);
    }
  }

  &.skinnySecondary {
    background-color: $vesta-light-blue;
    border-color: $vesta-light-blue;
    color: $white;
    height: $button-height-small;
    &:hover {
      background-color: rgba($vesta-light-blue, 0.75);
      border-color: rgba($vesta-light-blue, 0.75);
    }
  }

  &.pathOption{
    background-color: $white;
    border: solid;
    border-width: $border-size-medium;
    border-color: $border-color;
    border-radius: $radius-tiniest;
    color: $vesta-light-blue;
    height: 100px;
    width: $button-path-option-width;
    font-weight: bold;
  
  }

  &.primary {
    background-color: $vesta-pink;
    border-color: $vesta-pink;
    color: white;
    height: $button-height;
    line-height: $button-height;
    width: auto;

    span, a {
      color: white;
    }

    i {
      border-color: $white;
    }

    &:hover {
      background-color: rgba($vesta-pink, 0.75);
      border-color: rgba($vesta-pink, 0.75);
    }

    &:active {
      background-color: $vesta-pink;
      border-color: $vesta-pink;
    }

    &:disabled {
      background-color: $medium-grey;
      border-color:  $medium-grey;

      &:hover {
        background-color:  $medium-grey;
        border-color:  $medium-grey;
      }
    }
  }

  &.secondary {
    color: $color-primary;
    background-color: $vesta-light-blue;
    border-color: $vesta-light-blue;
    color: white;
    height: $button-height;
    line-height: $button-height;
    width: auto;
    display: block;
    
    span, a {
      color: white;
    }

    i {
      border-color: $white;
    }

    &:hover {
      background-color: rgba($vesta-light-blue, 0.75);
      border-color: rgba($vesta-light-blue, 0.75);
    }

    &:disabled {
      background-color: rgba($color-background-disabled, 1);
      border-color: rgba($color-background-disabled, 1);
      &:hover {
        background-color: rgba($color-background-disabled, 0.5);
        border-color: rgba($color-background-disabled, 0.5);
      }
    }
  }

  &.underline {
    text-decoration: underline;
    background: none;
    padding: 0;
    height: $button-height;
    line-height: $button-height;
    width: fit-content;
    font-size: $font-size-mobile;

    &:hover {
      text-decoration: none;
    }
  }

  &.darkPrimary {
    background-color: $vesta-dark-pink;
    border-color: $vesta-dark-pink;
    color: $white;
    width: fit-content;
    height: $button-height-small;
    line-height: $button-height-small;
    
    i {
      border-color: $white;
    }

    &:hover {
      background-color: rgba($vesta-dark-pink, 0.75);
      border-color: rgba($vesta-dark-pink, 0.75);
    }

    &:active {
      background-color: $vesta-dark-pink;
      border-color: $vesta-dark-pink;
    }
  }

  &.darkPrimaryInverted {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: $border-size-medium solid $vesta-dark-pink;
    width: fit-content;
    height: $button-height-small;

    &, p {
      color: $vesta-dark-pink;
    }

    i {
      border-color: $vesta-dark-pink;
      border-width: 0 $border-size-large $border-size-large 0;
    }
    
    &:hover {
      &, i {
        border-color: $vesta-pink;
      }

      &, p {
        color: $vesta-pink;
      }

      text-decoration: none;
    }

    &:active {
      &, i {
        border-color: $vesta-dark-pink;
      }

      &, p {
        color: $vesta-dark-pink;
      }
    }
  }

  &.darkSecondary {
    background-color: $vesta-dark-blue;
    border-color: $vesta-dark-blue;
    color: $white;
    width: fit-content;
    height: $button-height-small;
    line-height: $button-height-small;
    
    i {
      border-color: $white;
    }

    &:hover {
      background-color: rgba($vesta-dark-blue, 0.75);
      border-color: rgba($vesta-dark-blue, 0.75);
    }

    &:active {
      background-color: $vesta-dark-blue;
      border-color: $vesta-dark-blue;
    }
  }

  &.darkSecondaryInverted {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: $border-size-medium solid $vesta-dark-blue;
    width: fit-content;
    height: $button-height-small;

    &, p {
      color: $vesta-dark-blue;
    }
    
    i {
      border-color: $vesta-dark-blue;
      border-width: 0 $border-size-large $border-size-large 0;
    }
    
    &:hover {
      &, i {
        border-color: rgba($vesta-dark-blue, 0.75);
      }

      &, p {
        color: rgba($vesta-dark-blue, 0.75);
      }
      text-decoration: none;
    }

    &:active {
      &, i {
        border-color: $vesta-dark-blue;
      }

      &, p {
        color: $vesta-dark-blue;
      }
    }
  }

  &.darkPrimaryUnderline {
    text-decoration: underline;
    background: none;
    font-weight: bold;
    color: $vesta-dark-pink;
    width: fit-content;
    
    &:hover {
      text-decoration: none;
    }
  }

  i {
    border-width: 0 $border-size-medium $border-size-medium 0;
  }
}

.arrowButton {
  appearance: none;
  border: none;
  box-shadow: none;
  width: fit-content;
  outline: none;
  text-align: center;
  margin: auto;
  height: auto;
  background: none;
  padding: 0 $padding-tiny;

  &.primary {
    height: $button-height-small;
    line-height: $button-height-small;
  }

  &.secondary {
    height: $button-height-small;
    line-height: $button-height-small;
  }

  &:hover {
    text-decoration: underline;
  }

}

.linkButton {
  display: block;
  text-decoration-line: none; 
  position: relative;

  &.primary {
    i, div {
      position: absolute;
      top: 0;
      bottom: 0;
      right: $padding-medium;
      margin: auto;
      width: $icon-size / 1.2;
      height: $icon-size / 1.2;
    }
  }


}

.arrowLink {
  font-family: FuturaDemi;
  color: $vesta-light-blue;
  display: inherit;
  align-items: center;
}

.doubleArrowContent {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  .doubleArrowIconContainer {
    display: flex;
    position: relative;
    margin-left: $margin-tiny;
    height: $icon-size-small;
    width: $icon-size-medium;

    .doubleArrowIconLeft {
      position: absolute;
      width: $icon-size-small;
      height: $icon-size-small;
      left: 0;
    }
    
    .doubleArrowIconRight {
      position: absolute;
      width: $icon-size-small;
      height: $icon-size-small;
      right: 0;
    }
  }
}


.arrowClass {
  &.pos-left {
    margin-right: 10px;
  }

  &.pos-right {
    margin-left: 10px;
  }

 @include transitionTime;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  &.up {
    // down
   @include transitionTime;

    vertical-align: middle;
    margin-top: $margin-tinier;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    // up
   @include transitionTime;

    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 3px;
  }

  &.left {
    // right
   @include transitionTime;

    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.right {
    // left
   @include transitionTime;

    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
  .button {
    padding: 0 $padding-small;
  }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .button {
    height: $button-height;
    line-height: $button-height;
    padding: 0 $padding-small;
    
    &.underline {
      font-size: $font-size-smallText;
  
      &:hover {
        text-decoration: none;
      }
    }
  }
}