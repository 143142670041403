/*-- Arrows --*/
i {
    border: solid #6F6F6F;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 4px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    vertical-align: middle;
  }
  
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }