@import 'scss/base.scss';

.container {
  display: flex;
  width: fit-content;
  align-items: flex-start;

  .flag {
    width: $icon-size-small;
    height: $icon-size-small;
    margin-top: $margin-tiniest;
  }

  .text {
    position: relative;
    margin-left: $margin-tinier;
    font-size: $font-size-medium;

    &.blue {
      color: $vesta-medium-blue;
    }
    &.pink {
      color: $vesta-dark-pink;
    }
  }
}