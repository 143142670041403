@import "scss/base.scss";

.feedbackPage {
    background: $light-grey;
    min-height: 100vh;
    padding-bottom: $padding-bottom-tos-pp-offset;

}

.feedbackPageContainer {
    max-width: $report-page-max-width;
    margin: auto;
}

.feedbackContent {
    padding: 0 $padding-tiny;

    p, h1, h3, span {
        font-size: $font-size-small;
        margin-bottom: $margin-tiny;
    }
}

.right {
    label h1 {
        margin-bottom: $margin-tiniest;
    }
}

.submitFeedback {
    text-align: center;
    margin-top: $margin-tiny;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .feedbackPageContainer {
        max-width: $general-page-max-width;
        padding: 0px $padding-large;
        margin: auto;
    }

    .feedbackContent {
        display: flex;
        flex-wrap: wrap;
        padding: $padding-tiny;

        p, h1, h3, span {
            margin-bottom: $margin-small;
        }
    }

    .left {
        flex: 1;
        position: relative;
        padding-right: $padding-largest;
    }

    .right {
        flex: 1;
        padding-left: $padding-largest;
    }
}
