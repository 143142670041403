@import "scss/base.scss";

.genericHeaderBodyConstructorModal {

	.content {

		.headerBody {
			
			.body {
				padding-bottom: $padding-medium;
			}

			.bodyTitles {
				margin-bottom: $margin-small;
			}
		}
	}
}
