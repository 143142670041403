@import "scss/base.scss";

$icon-size-star: 2.2rem;
$icon-size-star-large: 2.6rem;

.starScale {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $margin-tiny;
}

div.starIconSize {
    width:  $icon-size-star;
    height: $icon-size-star;
}

.scale {
    display: flex;
    justify-content: space-between;
    width: $full;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    div.starIconSize {
        width:  $icon-size-star-large;
        height: $icon-size-star-large;
    }
    .starScale {
        margin-bottom: $margin-small;
    }
}
