@import "scss/base.scss";

p {
  width: fit-content;
  .title {
    font-weight: bold;
    font-family: 'FuturaHeavy';
    min-width: fit-content;
    max-height: fit-content;

    &.pink {
      color: $vesta-dark-pink;
    }
    &.blue {
      color: $vesta-dark-blue;
    }
  }  
}

