@import "scss/base.scss";

.homePage {
    background: $light-grey;
    min-height: 100vh;
    padding-bottom: $padding-bottom-tos-pp-offset;
}

.homeContainer {
    max-width: $general-page-max-width;
    height: $full;
    margin: auto;
    padding: 0 $padding-large;
    position: relative;
}

.homeHeader {
    display: flex;
    align-items: center;

    .headerText {
        display: flex;
        flex: 1;
        font-weight: normal;
        align-items: center;
        margin-left: $margin-large;
        font-size: $font-size-h2;
    }

    .headerLinkLeft,
    .headerLinkRight {
        display: none;
        height: fit-content;
        padding: 0;
        max-width: fit-content;
        justify-content: end;

        .headerLinkText {
            font-family: FuturaDemi;
        }

        .questionIcon {
            width: $icon-size;
            height: $icon-size;
            margin: 0 $margin-tiny;
        }
    }
    .headerLinkRight {
        margin-left: $margin-large;
    }
}


/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .homeHeader {
        .headerLinkLeft,
        .headerLinkRight {
            display: flex;
            align-items: center;
        }
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .homeHeader {
        .headerLinkLeft,
        .headerLinkRight {
            display: flex;
            align-items: center;
        }
    }

    .headerText {
        text-align: left;
    }

    .whoIsVesta {
        display: flex;
    }
}
