@import "scss/base.scss";

.reportSummary {
    border: solid 1px $border-color;
    border-radius: $radius-rounded-corners;
    padding: $padding-medium;

    .reportSummaryField {

        .header {
            display: flex;
            align-items: center;
            margin: $margin-tiny 0;
        }
    
        .iconSize {
            width: $icon-size;
            height: $icon-size;
            min-width: $icon-size;
            max-width: $icon-size;
            margin-right: $margin-tiny;
        }
    
        .permission {
            color: $vesta-pink;
            margin-left: 0.3rem;
        }

        .whatToExpect {
            margin-bottom: $margin-small;
        }
    }

}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
    .illustration {
        width: $full;
        height: $illustration-size;
    }
}
