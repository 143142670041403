@import "scss/base.scss";


.reportBreak {
  animation: fadeInFromTop 2s forwards;
  text-align: center;
  min-height: calc(100vh - 50px);
  position: relative;

  .prevButtonContainer {
    text-align: right;
  }

  .breakContent {
    max-width: $full;
    margin: auto;

    .breakHeader {
      display: flex;
      color: $white;
      align-items: center;
      margin: $margin-medium 0;
  
      .coffeeIcon {
        width: 3rem;
        height: 3rem;
        margin-right: $margin-tiny;
      }
    }
  }



  .body {

    p {
      text-align: left;
      margin-bottom: $margin-tiny;
    }

    .proceedOnlyIfComfortable {
      font-weight: bold;
    }
  }

  .bottomButtons {
    button {
      margin: $margin-small 0;
      color: $white;
      width: $full;
    }
  }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .reportBreak {
    .breakContent {
      max-width: 80%;
    }
  }
}
