@import "scss/base.scss";

.consentFieldsContainer {

  .displayNone {
    display: none;

    + p {
      display: none;
    }
  }

  .consentField, .accessSupportInfo {
    margin-bottom: $margin-tiny;
  }

  .accessSupportInfo {
    padding: $padding-tiny;
    font-size: $font-size-smallText;
    background-color: $gradient-blue;
    color: $white;
    border-radius: $radius-tiniest;
  }

  .questionIcon {
    width: $icon-size;
    height: $icon-size;
    margin: 0 $margin-tiny;
    display: inline-block;
  }
  
  .link {
    color: $white;
    text-decoration: underline;
  }
}
