@import "scss/base.scss";

.reportPage {
  padding-bottom: $padding-bottom-tos-pp-offset;

  .closestSupportQuestionContainer {
    max-width: 480px;
    margin: auto;
  }

  .reportContainer {
      .left {
        p {
          margin-bottom: $margin-large;
        }
        .illustration {
          width: $full;
          height: $illustration-size-small;
          margin: $margin-tiny 0;
          position: relative;
          bottom: 50px;
          left: 70px;
        }
        .youAreInControl {
          color: $vesta-pink;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;

        .accessSupportInfo {
          padding: $padding-tiny;
          font-size: $font-size-microText;
          background-color: $gradient-blue;
          color: $white;
          border-radius: $radius-tiniest;
          font-weight: bolder;
          min-height: 50px;
          width: $full;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-top: $margin-tiny;
          margin-bottom: $margin-tiny;
        }
        
        .vestaPink {
          color: $vesta-pink
        }

        .supportLinks {
          font-weight: bolder;
          display: flex;
          flex-direction: row;
        }

        .iconSize {
            width: 4rem;
            height: 1.5rem;
            display: inline-block;
            vertical-align: sub;
            margin-right: 15px;
          }
        
        .body {
          .vestaDarkPink {
            color: $vesta-dark-pink
          }
        }
        .intakeForm, .crisisLink, .crisisPhone {
          color: $white;
          margin-top: $margin-tiny;
          margin-bottom: $margin-tiny;
        }

        .link {
          font-weight: lighter;
          color: $white;
          text-decoration: underline;
        }

        .number {
          font-weight: lighter;
          color: $white;

        }

        .externalLinkIcon {
          color: $white;
          width: $icon-size-small;
          height: $icon-size-small;
          display: inline-block;
          margin-left: 10px;
          cursor: pointer;
          }
      
        .reportStartButtons {
          display: flex;
          flex-direction: column;

          a {
            min-width: $button-min-width;
          }
        }
        .startButton {
          margin-top: $margin-small;
        }
      }
  }
}

.locationInput {
  margin-top: $margin-tiny;
}

.downloadFlagText {
  margin-top: $margin-spacer;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .reportPage {
    background: transparent;
    @include grayPanel;

    .reportContainer {
      .reportContent {
        display: flex;
        padding: 0px;
        margin-top: $margin-large * 2;


        .left {
          flex: 1;
          position: relative;
          padding-right: $padding-largest;
          padding-bottom: $padding-largest;
          @include transitionTime;

          width: $report-form-width;
          min-width: $report-form-width;
          max-width: $report-form-width;
          margin: 0px;
        }

        .right {
          width: calc(100% - #{$report-form-width});
          flex: 1;
          overflow: visible;
          position: fixed;
          right: 0;
          top: 0;
          margin-top: $margin-spacer-large;

          padding: $padding-largest;
          padding-top: 0;
        }
      }
    }
  }
}
