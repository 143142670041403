@import "scss/base.scss";


.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title {
    text-align: center;
    margin-top: $margin-largest;
    font-size: $font-size-h5;
    margin-bottom: $margin-large;
  }
}