@import "scss/base.scss";

.deleteModalContent {
    text-align: center;

    button {
        margin-top: $margin-medium;
    }

    .buttonsContainer {
        display: flex;
        flex-direction: column;
    }
}