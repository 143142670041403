@import "scss/base.scss";

.inputToggleContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.locationInput label {
    color: $dark-grey;
    font-size: $font-size-small
}

.complexionQuestion, .hairColourQuestion {
    align-items: center;

    p {
        white-space: nowrap;
        width: 110px;
    }
}

.errorMessage {
    color: $color-form-error;
    font-size: $font-size-small;
    font-style: italic;
}

/* Media Queries */
// Tablet, Desktop
@media only screen and (min-width:$tablet-min-breakpoint) {
    .complexionQuestion, .hairColourQuestion {
        display: flex;
        align-items: center;
    
        p {
            white-space: nowrap;
            width: 110px;
        }
    }

    .locationInput label {
        color: $dark-grey;
        font-size: $font-size;
    }
}