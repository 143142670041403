@import 'scss/base.scss';

.leftContainer {
    width: $full;
    display: flex;
    flex-direction: column;
    
    h3 {
        margin-bottom: $margin-tiny;

        &.documentPage {
            color: $vesta-dark-pink;
        }
        &.clickthroughPage {
            color: $vesta-dark-blue;
        }
    }    
}

.leftJustifiedButtonContainer {
    display: flex;
    justify-content: flex-start;
    width: $full;
    margin-bottom: $margin-large;
    align-items: start;

    .leftJustifiedButton {
        width: $full;
        margin: 0;
    }
}

.illustration {
    display: flex;
    justify-content: center;
    width: $full;
}

.point1,
.point2 {
    margin: $margin-small 0;
    width: $full;
}

.learnMore {
    // external link icon
    div {
        display: none;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .leftContainer {
        width: $full;
    }

    .leftJustifiedButtonContainer {
        .leftJustifiedButton {
            width: auto;
        }
    }

    .learnMore {
        div {
            display: flex;
        }
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .leftContainer {
        width: $half;
        margin-right: $margin-large;
    }

    .leftJustifiedButtonContainer {
        .leftJustifiedButton {
            width: auto;
        }
    }

    .point1 {
        margin: $margin-largest auto;
    }

    .learnMore {
        @include fadeInFromBottom;
        margin-top: $margin-tiny;
        justify-content: center;

        div {
            display: flex;
        }
    }

    .illustration {
        height: $illustration-size-tiny;
        margin-top: $margin-largest;
    }
}
