@import "scss/base.scss";

.rightContainer {
    width: $full;
    display: flex;
    flex-direction: column;
}

.leftJustifiedButtonContainer {
    display: flex;
    justify-content: start;
    width: $full;
    margin-bottom: $margin-large;

    .leftJustifiedButton {
        width: $full;
        margin: 0;
    }
}

.surveyLinkContainer {
    cursor: pointer;

    &.documentPage {
        margin-top: $margin-small;
        margin-bottom: $margin-largest;
    }
    &.clickthroughPage {
        margin-top: $margin-large;
        margin-bottom: 0;
    }
}

.surveyLinkContent {
    display: inline-flex;
    align-items: flex-end;

    .surveyLinkText {
        margin-left: $margin-tiniest;
        display: inline;
        color: $white;
    }

    .externalLinkIcon {
        width: $icon-size-small;
        height: $icon-size-small;
        margin-bottom: $margin-tiniest;
        margin-left: $margin-tinier;
        margin-right: $margin-tinier;
    }
}

.graphicContainer {
    display: flex;
    justify-content: center;
    width: $full;
}

.printAnotherCopy {
    margin: $margin-small 0;
    width: $full;
}

.point2 {
    margin-bottom: $margin-small;
    width: $full;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .rightContainer {
        width: $full;
    }

    .surveyLinkContainer {
        width: $two-third;
    }

    .leftJustifiedButtonContainer {
        .leftJustifiedButton {
            width: auto;
        }
    }
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .rightContainer {
        width: $half;
        margin-left: $margin-large;
    }
  
    .leftJustifiedButtonContainer {
        .leftJustifiedButton {
            width: auto;
        }
    }

    .printAnotherCopy {
        margin: $margin-largest auto;
    }

    .point2 {
        margin-bottom: $margin-largest;
    }

    .surveyLinkContainer {
        &.documentPage {
            margin-top: 0;
            margin-bottom: $margin-largest;
        }
        &.clickthroughPage {
            margin-top: $margin-large * 2;
            margin-bottom: 0;
        }
    }

    .accessSavedReport {
        min-height: $home-card-min-height-small;
    }
}
